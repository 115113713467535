<div *ngIf="organizationUnit" class="d-flex align-items-center justify-content-between">
    <!-- START ORGANIZATION-UNIT NAME -->
    <span *ngIf="organizationUnit.displayName">
        {{ organizationUnit.displayName }}
    </span>
    <!-- END ORGANIZATION-UNIT NAME -->

    <!--START ORGANIZATION-UNIT ACTIONS-->
    <span class="mx-1">
        <button *ngIf="withDetail && isGranted(permissionsConst.OrganizationUnit_Manage)" type="button" class="btn btn-default btn-sm mx-1" (click)="seeOrganizationUnitDetail(organizationUnit)" [title]="'Details' | localize">
            <em class="fas fa-eye"></em>
            <span class="sr-only"> {{ 'Details' | localize }} </span>
        </button>

        <button
            type="button"
            class="btn btn-default btn-sm mx-1"
            *ngIf="isGranted(permissionsConst.OrganizationUnit_Edit) && withEdit"
            (click)="editOrganizationUnit(organizationUnit)"
            [title]="'Edit' | localize"
        >
            <em class="fas fa-pencil-alt text-success"></em>
            <span class="sr-only"> {{ 'Edit' | localize }} </span>
        </button>

        <button
            type="button"
            class="btn btn-default btn-sm mx-1"
            *ngIf="isGranted(permissionsConst.OrganizationUnit_Delete) && withDelete"
            (click)="deleteOrganizationUnit(organizationUnit)"
            [title]="'Delete' | localize"
        >
            <em class="fas fa-trash-alt text-danger"></em>
            <span class="sr-only"> {{ 'Delete' | localize }} </span>
        </button>
    </span>
    <!--END ORGANIZATION-UNIT ACTIONS-->
</div>
