<section class="mb-3">
    <div class="card">
        <div class="card-header">
            <div class="col-lg-12">
                <div class="d-flex flex-row align-items-center justify-content-between mb-2">
                    <h4 class="m-0">{{ 'Reminders' | localize }}</h4>

                    <button type="button" *ngIf="isGranted(permissionsConst.ScheduledNotification_Create)" [disabled]="isAddNotificationActive" (click)="addNotification()" class="btn btn-primary btn-sm mt-2">
                        <em class="fas fa-plus mr-2"></em>
                        {{ 'Add' | localize }}
                    </button>
                </div>
            </div>
        </div>

        <div *ngIf="isGranted(permissionsConst.ScheduledNotification_Edit)" class="card-body table-responsive overflow-inherit p-0" [busy]="isTableLoading">
            <div class="m-2">
                <scheduled-notification
                    [hidden]="!isAddNotificationActive"
                    (updateList)="updateList()"
                    [isNew]="true"
                    [entityId]="entityId"
                    [entityType]="entityType"
                ></scheduled-notification>

                <hr *ngIf="isAddNotificationActive && scheduledNotifications?.length > 0" />

                <div
                    class="table table-hover text-nowrap"
                    [busy]="isTableLoading"
                    *ngFor="
                        let notification of scheduledNotifications
                            | paginate
                                : {
                                      id: 'notificationListPaged',
                                      itemsPerPage: pageSize,
                                      currentPage: pageNumber,
                                      totalItems: totalItems
                                  };
                        let i = index
                    "
                >
                    <scheduled-notification
                        [isNew]="false"
                        (updateList)="updateList()"
                        [notification]="notification"
                        [entityId]="entityId"
                        [entityType]="entityType"
                    ></scheduled-notification>

                    <hr *ngIf="i < scheduledNotifications?.length - 1" />
                </div>

                <div *ngIf="scheduledNotifications?.length == 0 && !isAddNotificationActive" class="my-4 text-center">
                    <span>{{ 'NoScheduledNotificationFound' | localize }}</span>
                </div>
            </div>
        </div>

        <div class="card-footer bg-light border-top">
            <div class="row">
                <div class="col-sm-4 col-12 text-sm-left text-center">
                    <button class="btn btn-default btn-sm" (click)="refresh()">
                        <em class="fas fa-sync mr-2"></em>
                        {{ 'Refresh' | localize }}
                    </button>
                </div>
                <div class="col-sm-4 col-12 text-center">
                    <p class="mb-0 my-2">
                        {{ 'TotalRecordsCount' | localize: totalItems }}
                    </p>
                </div>
                <div class="col-sm-4 col-12">
                    <div class="float-sm-right m-auto">
                        <abp-pagination-controls id="notificationListPaged" (pageChange)="getDataPage($event)"></abp-pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
