import { Component, Injector, Input, OnDestroy } from '@angular/core';
import { AppComponentBase } from '@shared/app-component-base';
import {
    ContractDto,
    ContractFileDto,
    ContractFileServiceProxy,
    ServiceDto,
    ServiceFileDto,
    ServiceFileServiceProxy,
    UserDto,
    UserFileDto,
    UserFileServiceProxy,
} from '@shared/service-proxies/service-proxies';
import { Subscription } from 'rxjs';

@Component({
    selector: 'file-entity-list',
    templateUrl: './file-entity-list.component.html',
    styleUrls: ['./file-entity-list.component.scss'],
})
export class FileEntityListComponent extends AppComponentBase implements OnDestroy {
    @Input() associatedEntities: (UserFileDto | ServiceDto | ContractDto)[];
    private subscription: Subscription = new Subscription();

    constructor(
        injector: Injector,
        private userFileService: UserFileServiceProxy,
        private contractFileService: ContractFileServiceProxy,
        private serviceFileService: ServiceFileServiceProxy
    ) {
        super(injector);
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    isUser(item) {
        return item instanceof UserFileDto;
    }

    isService(item) {
        return item instanceof ServiceFileDto;
    }

    isContract(item) {
        return item instanceof ContractFileDto;
    }

    removeAssociation(entity, index) {
        let ob;
        abp.message.confirm(this.l('FileAssociatedEntityDeleteWarning'), '', (confirm: boolean) => {
            if (confirm) {
                if (this.isUser(entity)) {
                    ob = this.userFileService.delete(entity.id);
                }
                if (this.isService(entity)) {
                    ob = this.serviceFileService.delete(entity.id);
                }
                if (this.isContract(entity)) {
                    ob = this.contractFileService.delete(entity.id);
                }

                this.subscription.add(
                    ob.subscribe((res) => {
                        this.associatedEntities.splice(index, 1);
                        this.notify.success('OperationSuccessfull');
                    })
                );
            } else {
                this.notify.info('OperationCanceled');
            }
        });
    }
}
