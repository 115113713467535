<div *ngIf="contract" class="d-flex">
    <div class="w-25">{{ contract.title }}</div>

    <div class="d-flex align-items-center justify-content-start w-50">
        <span class="small w-50 text-left d-inline-block" *ngIf="customerVisible">
            <span class="text-uppercase text-muted">{{ 'Customer' | localize }}<br /></span>
            <customer-basic-item *ngIf="leaderCustomer" class="d-inline" [customer]="leaderCustomer" [withRemoveAction]="false" [withShadow]="false"></customer-basic-item>
        </span>

        <span class="small text-left d-inline-block mx-4">
            <span class="text-uppercase text-muted">{{ 'StartDate' | localize }}<br /></span>
            {{ contract.startDate.toDate() | date: 'dd/MM/yyyy' }}
        </span>

        <span class="small text-left d-inline-block mx-4">
            <span class="text-uppercase text-muted">{{ 'EndDate' | localize }}<br /></span>
            {{ contract.endDate.toDate() | date: 'dd/MM/yyyy' }}
        </span>
        <!--END CONTRACT INFO-->
    </div>

    <!--START CONTRACT ACTIONS-->
    <div class="w-25 d-flex flex-column justify-content-center">
        <div class="d-flex justify-content-end">
            <button type="button" class="btn btn-default btn-sm mx-1" (click)="seeContractDetail(contract)" [title]="'Detail' | localize">
                <em class="fas fa-eye"></em>
                <span class="sr-only"> {{ 'Detail' | localize }} </span>
            </button>

            <button
                type="button"
                class="btn btn-default btn-sm mx-1"
                *ngIf="isGranted(permissionsConst.Contract_Edit)"
                (click)="editContract(contract)"
                [title]="'Edit' | localize"
            >
                <em class="fas fa-pencil-alt text-success"></em>
                <span class="sr-only"> {{ 'Edit' | localize }} </span>
            </button>

            <button
                type="button"
                class="btn btn-default btn-sm mx-1"
                *ngIf="isGranted(permissionsConst.Contract_Delete)"
                (click)="deleteContract.emit(contract)"
                [title]="'Delete' | localize"
            >
                <em class="fas fa-trash-alt text-danger"></em>
                <span class="sr-only"> {{ 'Delete' | localize }} </span>
            </button>
        </div>
    </div>
    <!--END CONTRACT ACTIONS-->
</div>
