<div class="input-group" [ngClass]="{ 'input-group-sm': inputSize === 'sm', 'input-group-lg': inputSize === 'lg' }">
    <div class="input-group-prepend">
        <span class="input-group-text" [tooltip]="'UserTypeaheadHelper' | localize">
            <em class="fas fa-info"></em>
        </span>
    </div>

    <input
        type="text"
        class="form-control"
        [placeholder]="'SearchWithThreeDot' | localize"
        [(ngModel)]="selectedUser"
        [ngModelOptions]="{ standalone: true }"
        (input)="getUsersOnInput($event)"
        [typeaheadItemTemplate]="usersTemplate"
        (typeaheadOnSelect)="typeAheadOnSelect($event)"
        [typeahead]="usersObs$"
        [typeaheadAsync]="true"
        [isAnimated]="true"
        typeaheadOptionField="user.fullName"
        autocomplete="off"
    />
    <div class="input-group-append" *ngIf="isSearchingUser">
        <span class="input-group-text">
            <em class="fas fa-spinner fa-spin"></em>
        </span>
    </div>

    <ng-template #usersTemplate let-user="item" class="dropdown-users" let-index="index">
        {{ user.fullName }}
    </ng-template>
</div>
