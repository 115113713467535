import { Component, EventEmitter, Injector, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ValidatorsHelper } from '@shared/helpers/ValidatorsHelper';
import { PagedListingComponentBase, PagedRequestDto } from '@shared/paged-listing-component-base';
import { HRRequestDto, HRRequestServiceProxy, HRRequestSupplyingObjectDto, HRRequestSupplyingObjectDtoPagedResultDto } from '@shared/service-proxies/service-proxies';
import { HrRequestStateConst } from '@hrrequests/hrrequest-const.model';

@Component({
    selector: 'hrrequest-detail-supplying-object-list',
    templateUrl: './hrrequest-detail-supplying-object-list.component.html',
})
export class HrrequestDetailSupplyingObjectListComponent extends PagedListingComponentBase<HRRequestSupplyingObjectDto> implements OnInit, OnChanges, OnDestroy {
    @Input() hRRequest: HRRequestDto;
    private subscription: Subscription = new Subscription();
    hrRequestSupplyingObject: FormArray = new FormArray([]);
    hrRequestSupplyingObjectList: HRRequestSupplyingObjectDto[] = [];
    private decimalValueKeys = ['unitPrice', 'totalPrice', 'totalOrderPurchase', 'residualPurchase'];

    canChangeHrRequest: boolean;
    @Output() updateDetail: EventEmitter<HRRequestSupplyingObjectDto[]> = new EventEmitter<HRRequestSupplyingObjectDto[]>();

    get hrRequestSupplyingObjectFormArray() {
        return this.hrRequestSupplyingObject as FormArray;
    }

    get hrRequestState() {
        return HrRequestStateConst;
    }

    get isNotDraft() {
        return this.hRRequest.hrRequestState.description !== this.hrRequestState.Draft;
    }

    constructor(private injector: Injector, private hrRequestSupplyingService: HRRequestServiceProxy) {
        super(injector);
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.canChangeHrRequest = this.appSession.userId == this.hRRequest.creatorUser.id;
        this.refresh();
    }

    ngOnInit(): void {
        this.refresh();
    }

    createSupplyingObjectFormArray(object: HRRequestSupplyingObjectDto[]) {
        this.hrRequestSupplyingObject = new FormArray([]);
        object.forEach((el) => {
            this.addNewSupplyingObject(el);
        });
        if (!this.canChangeHrRequest) {
            this.hrRequestSupplyingObject.disable();
        }
    }

    addNewSupplyingObject(hrRequestSupplyingObjectElement: HRRequestSupplyingObjectDto) {
        let elFormGroup = new FormGroup(
            {
                id: new FormControl(0),
                name: new FormControl(''),
                quantity: new FormControl(0),
                unitPrice: new FormControl(0),
                totalPrice: new FormControl(0),
                hrRequestId: new FormControl(this.hRRequest.id),
                hrRequestState: new FormControl(this.hRRequest.hrRequestState.description),
                totalOrderPurchase: new FormControl(0, [ValidatorsHelper.twoDecimalRegexValidator]),
                residualPurchase: new FormControl(0, [ValidatorsHelper.twoDecimalRegexValidator]),
                currencyType: new FormControl(this.hRRequest.currencyType.description),
            },
            [ValidatorsHelper.periodValidator]
        );
        elFormGroup.get('totalPrice').disable();
        if (hrRequestSupplyingObjectElement) {
            elFormGroup.patchValue(this.parseDecimalsAsNativeValues(this.decimalValueKeys, hrRequestSupplyingObjectElement));
        }
        this.hrRequestSupplyingObject.push(elFormGroup);
    }

    cancelAddItem(index) {
        this.hrRequestSupplyingObject.removeAt(index);
    }

    displayChanges() {
        this.refresh();
    }

    protected list(request: PagedRequestDto, pageNumber: number, finishedCallback: Function): void {
        this.startLoading();
        const sub = this.hrRequestSupplyingService
            .getSupplyingObjectsByHrRequestId(this.hRRequest.id, request.skipCount, request.maxResultCount)
            .pipe(
                finalize(() => {
                    finishedCallback();
                    this.stopLoading();
                })
            )
            .subscribe((result: HRRequestSupplyingObjectDtoPagedResultDto) => {
                this.hrRequestSupplyingObjectList = result.items;
                this.createSupplyingObjectFormArray(result.items);
                this.showPaging(result, pageNumber);
                this.updateDetail.emit(this.hrRequestSupplyingObjectList);
            });
        this.subscription.add(sub);
    }

    protected delete(entity: HRRequestSupplyingObjectDto): void {}

    updateHRRequest() {
        this.refresh();
    }
}
