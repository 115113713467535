import { Injector, Pipe, PipeTransform } from '@angular/core';
import { AppComponentBase } from '@shared/app-component-base';

@Pipe({
    name: 'minutesToTime',
    pure: true,
})
export class MinutesToTimePipe extends AppComponentBase implements PipeTransform {
    constructor(injector: Injector) {
        super(injector);
    }
    transform(totalMinutes: number) {
        if (totalMinutes) {
            return this.minutesToTime(totalMinutes);
        } else return '';
    }
}
