import { Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponentBase } from '@shared/app-component-base';
import { HRRequestTypeOrganizationUnitsDto, HRRequestTypeOrganizationUnitsServiceProxy } from '@shared/service-proxies/service-proxies';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'hrrequest-approvation-flow-item',
    templateUrl: './hrrequest-approvation-flow-item.component.html',
})
export class HrrequestApprovationFlowItemComponent extends AppComponentBase implements OnInit, OnDestroy {
    @Input() hrrequestApprovalFlow: HRRequestTypeOrganizationUnitsDto;
    @Output() refreshList: EventEmitter<void> = new EventEmitter<void>();

    constructor(private injector: Injector, private router: Router, private activatedRoute: ActivatedRoute, private hRRequestTypeOrganizationUnitsService: HRRequestTypeOrganizationUnitsServiceProxy) {
        super(injector);
    }

    ngOnInit(): void {}

    ngOnDestroy() {}

    deleteHRRequestApprovalFlow() {
        abp.message.confirm(this.l('ApprovationFlowDeleteWarningMessage'), undefined, (result: boolean) => {
            if (result) {
                this.hRRequestTypeOrganizationUnitsService
                    .delete(this.hrrequestApprovalFlow.id)
                    .pipe(
                        finalize(() => {
                            abp.notify.success(this.l('SuccessfullyDeleted'));
                            this.refreshList.emit();
                        })
                    )
                    .subscribe(() => {});
            }
        });
    }

    seeHRRequestApprovalFlowDetail() {
        this.router.navigate(['app/hrrequest', 'approvationFlow', this.hrrequestApprovalFlow.id, 'detail'], { relativeTo: this.activatedRoute.root });
    }
}
