<form [formGroup]="form" (ngSubmit)="save()">
    <div class="d-flex">
        <div class="d-flex flex-column col-lg">
            <div class="row">
                <div class="col">
                    <div class="form-group row mr-0 ml-0 required">
                        <label class="col-form-label w-100 p-0 m-0" [for]="'notificationName' + notificationId" required>
                            {{ 'ScheduledNotificationName' | localize }}
                        </label>

                        <div class="w-100">
                            <input
                                type="text"
                                class="form-control"
                                name="notificationName"
                                [id]="'notificationName' + notificationId"
                                formControlName="notificationName"
                                #notificationNameEl
                            />
                            <abp-validation-summary [control]="controls.notificationName" [controlEl]="notificationNameEl"></abp-validation-summary>
                        </div>
                    </div>
                </div>

                <div class="col">
                    <div class="form-group row mr-0 ml-0">
                        <label class="w-100 m-0" [for]="'description' + notificationId">
                            {{ 'ScheduledNotificationDescription' | localize }}
                        </label>
                        <div class="w-100">
                            <input type="text" class="form-control" name="description" [id]="'description' + notificationId" formControlName="description" #descriptionEl />
                            <abp-validation-summary [control]="controls.description" [controlEl]="descriptionEl"></abp-validation-summary>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-3">
                    <div class="form-group row mr-0 ml-0 required">
                        <label class="col-form-label w-100 p-0 m-0" [for]="'dueDate' + notificationId">
                            {{ 'DueDate' | localize }}
                        </label>
                        <div class="w-100">
                            <div class="input-group">
                                <input
                                    type="text"
                                    bsDatepicker
                                    #dueDateDp="bsDatepicker"
                                    [bsConfig]="bsDatepickerConfig"
                                    [value]="form.controls.dueDate.value | date: 'dd/MM/yyyy'"
                                    class="form-control not-disabled"
                                    name="dueDate"
                                    [id]="'dueDate' + notificationId"
                                    formControlName="dueDate"
                                    #dueDateEl
                                    onkeydown="return false"
                                    readonly
                                    autocomplete="off"
                                />
                                <div class="input-group-append">
                                    <span role="button" class="input-group-text" (click)="dueDateDp.toggle()" [attr.aria-expanded]="dueDateDp.isOpen">
                                        <em class="fas fa-calendar-alt fa-sm"></em>
                                    </span>
                                </div>
                            </div>
                            <abp-validation-summary [control]="controls.dueDate" [controlEl]="dueDateEl"></abp-validation-summary>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group row mr-0 ml-0">
                        <label class="w-100 m-0" [for]="'userIds' + notificationId">
                            {{ 'SendTo' | localize }}
                        </label>
                        <div class="w-100">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" [tooltip]="'UserTypeaheadHelper' | localize">
                                        <em class="fas fa-info"></em>
                                    </span>
                                </div>
                                <input
                                    type="text"
                                    [id]="'userIds' + notificationId"
                                    class="form-control"
                                    [placeholder]="'SearchWithThreeDot' | localize"
                                    [(ngModel)]="selectedUser"
                                    [ngModelOptions]="{ standalone: true }"
                                    (input)="getUsersOnInput($event)"
                                    [typeaheadItemTemplate]="usersTemplate"
                                    (typeaheadOnSelect)="typeAheadOnSelect($event)"
                                    [typeahead]="usersObs$"
                                    [typeaheadAsync]="true"
                                    [isAnimated]="true"
                                    typeaheadOptionField="area"
                                    autocomplete="off"
                                />

                                <div class="input-group-append" *ngIf="isSearchingUser">
                                    <span class="input-group-text">
                                        <em class="fas fa-spinner fa-spin"></em>
                                    </span>
                                </div>

                                <ng-template #usersTemplate let-user="item" class="dropdown-users" let-index="index"> {{ user.fullName }} </ng-template>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="form-group row mr-0 ml-0">
                        <label class="w-100 m-0" [for]="'sendToAll' + notificationId">
                            {{ 'SendToAll' | localize }}
                        </label>
                        <div class="w-100">
                            <div class="custom-control custom-checkbox">
                                <input
                                    type="checkbox"
                                    [(ngModel)]="form.value.sendToAll"
                                    class="custom-control-input"
                                    name="sendToAll"
                                    [id]="'sendToAll' + notificationId"
                                    formControlName="sendToAll"
                                    #sendToAllEl
                                />
                                <label class="custom-control-label" [for]="'sendToAll' + notificationId">&nbsp;</label>
                            </div>

                            <abp-validation-summary [control]="controls.sendToAll" [controlEl]="sendToAllEl"></abp-validation-summary>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="form-group row mr-0 ml-0">
                        <label class="w-100 m-0">
                            {{ 'Recipients' | localize }}
                        </label>
                        <div class="w-100">
                            <user-delete-item *ngFor="let user of usersList" [user]="user" (getItemToDelete)="getItemToDelete($event)" class="mr-2 mb-2"></user-delete-item>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex col-xs align-items-center justify-content-center">
            <button
                type="submit"
                class="btn btn-default btn-sm mx-1"
                [disabled]="isSaveDisabled()"
                [title]="'Save' | localize"
            >
                <em class="fas fa-save text-success"></em>
                <span class="sr-only"> {{ 'Save' | localize }}</span>
            </button>

            <button [disabled]="isCancelDisabled()" type="button" class="btn btn-default btn-sm mx-1" [title]="'Cancel' | localize" (click)="reset()">
                <em class="fas fa-undo"></em>
                <span class="sr-only"> {{ 'Cancel' | localize }}</span>
            </button>

            <button *ngIf="!isNew" type="button" class="btn btn-default btn-sm mx-1" [title]="'Delete' | localize" (click)="delete()">
                <em class="fas fa-trash-alt text-danger"></em>
                <span class="sr-only"> {{ 'Delete' | localize }}</span>
            </button>
        </div>
    </div>
</form>
