<section class="mb-3" [@routerTransition]>
    <div class="card">
        <div class="card-header">
            <div class="col-lg-12">
                <div class="d-flex flex-row align-items-center justify-content-between mb-2">
                    <h4 class="m-0">{{ 'Services' | localize }}</h4>

                    <button type="button" *ngIf="isGranted(permissionsConst.Service_Create)" class="btn btn-default btn-sm bg-blue" (click)="createService()">
                        <em class="fa fa-plus mr-2"></em>
                        {{ 'Create' | localize }}
                    </button>
                </div>
            </div>
        </div>

        <div class="card-body table-responsive" [busy]="isTableLoading">
            <ng-container
                *ngFor="
                    let service of services
                        | paginate
                            : {
                                  id: 'operatorServicesList',
                                  itemsPerPage: pageSize,
                                  currentPage: pageNumber,
                                  totalItems: totalItems
                              };
                    let i = index
                "
            >
                <div class="d-flex flex-row align-items-center justify-content-between">
                    <service-item class="w-100" [service]="service" [withEntityTypeIcon]="false" [withEntityTypeBadge]="false" (refreshList)="refresh()"></service-item>
                </div>

                <hr *ngIf="i < services?.length - 1" />
            </ng-container>

            <div *ngIf="services?.length == 0" class="text-center">
                <span>{{ 'NoServiceFound' | localize }}</span>
            </div>
        </div>

        <div class="card-footer bg-light border-top">
            <div class="row">
                <div class="col-sm-4 col-12 text-sm-left text-center">
                    <button class="btn btn-default btn-sm" (click)="refresh()">
                        <em class="fas fa-sync mr-2"></em>
                        {{ 'Refresh' | localize }}
                    </button>
                </div>

                <div class="col-sm-4 col-12 text-center">
                    <p class="mb-0 my-2">
                        {{ 'TotalRecordsCount' | localize: totalItems }}
                    </p>
                </div>

                <div class="col-sm-4 col-12">
                    <div class="float-sm-right m-auto">
                        <abp-pagination-controls id="operatorServicesList" (pageChange)="getDataPage($event)"></abp-pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
