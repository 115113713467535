import { Component, EventEmitter, Injector, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { PagedListingComponentBase, PagedRequestDto } from '@shared/paged-listing-component-base';
import {
    HRRequestServiceProxy,
    HRRequestTypeDto,
    HRRequestTypeOrganizationUnitsDto,
    HRRequestTypeOrganizationUnitsDtoPagedResultDto,
    HRRequestTypeOrganizationUnitsServiceProxy,
    OrganizationUnitDto,
    OrganizationUnitServiceProxy,
} from '@shared/service-proxies/service-proxies';
import { Observable, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

class PagedApprovalFlowRequestDto extends PagedRequestDto {
    keyword: string;
}

@Component({
    selector: 'hrrequest-approvation-flow-list',
    templateUrl: './hrrequest-approvation-flow-list.component.html',
    animations: [appModuleAnimation()],
})
export class HrrequestApprovationFlowListComponent extends PagedListingComponentBase<any> implements OnDestroy, OnChanges, OnInit {
    @Input() organizationUnitId: number;
    @Input() externalView: boolean = false;

    private subscription: Subscription = new Subscription();
    hRRequestTypeOrganizationUnits: HRRequestTypeOrganizationUnitsDto[];

    form: FormGroup;
    organizationUnits$: Observable<OrganizationUnitDto[]>;

    hRRequestIds: number;
    organizationUnitIds: number;

    hrrequestData = [];

    constructor(
        injector: Injector,
        private hRRequestTypeOrganizationUnitsService: HRRequestTypeOrganizationUnitsServiceProxy,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private hRRequestService: HRRequestServiceProxy,
        private organizationUnitService: OrganizationUnitServiceProxy
    ) {
        super(injector);
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    ngOnChanges() {
        this.refresh();
    }

    ngOnInit(): void {
        if (!this.externalView) this.setTitle('ApprovalFlows');
        this.refresh();

        const sub = this.hRRequestService.getHRRequestTypes().subscribe((hrrequests) => {
            hrrequests.forEach((hrrequest) => {
                hrrequest.description = this.l(hrrequest.description);
            });
            this.hrrequestData = hrrequests;
        });
        this.subscription.add(sub);

        this.organizationUnits$ = this.organizationUnitService.getOrganizationUnitsByKeyword('');
        this.form = new FormGroup({
            hrRequestType: new FormGroup({
                id: new FormControl(null, Validators.required),
                description: new FormControl(''),
            }),
            organizationUnit: new FormGroup({
                id: new FormControl(null, Validators.required),
                displayName: new FormControl(''),
            }),
        });
    }

    protected list(request: PagedApprovalFlowRequestDto, pageNumber: number, finishedCallback: Function): void {
        if (this.form) {
            this.hRRequestIds = this.form.value.hrRequestType.id ? this.form.value.hrRequestType.id : undefined;
            this.organizationUnitIds = this.form.value.organizationUnit.id ? this.form.value.organizationUnit.id : undefined;
        }
        request.skipCount = !isNaN(request.skipCount) ? request.skipCount : 0;

        const sub = this.hRRequestTypeOrganizationUnitsService
            .getAll(this.hRRequestIds, this.externalView ? this.organizationUnitId : this.organizationUnitIds, undefined, request.skipCount, request.maxResultCount)
            .pipe(
                finalize(() => {
                    finishedCallback();
                })
            )
            .subscribe((result: HRRequestTypeOrganizationUnitsDtoPagedResultDto) => {
                this.hRRequestTypeOrganizationUnits = result.items;
                this.showPaging(result, pageNumber);
            });
        this.subscription.add(sub);
    }

    protected delete(entity): void {}

    createApprovalFlow() {
        if (this.externalView) this.router.navigate(['app/hrrequest', this.organizationUnitId, 'approvationFlow'], { relativeTo: this.activatedRoute.root });
        else this.router.navigate(['app/hrrequest', 'approvationFlow'], { relativeTo: this.activatedRoute.root });
    }
}
