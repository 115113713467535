import { Component, Injector, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { AppComponentBase } from '@shared/app-component-base';
import { Subscription } from 'rxjs';
import { AbpValidationError } from './abp-validation.api';

@Component({
    selector: 'abp-validation-summary',
    templateUrl: './abp-validation.summary.component.html',
})
export class AbpValidationSummaryComponent extends AppComponentBase implements OnInit, OnDestroy {
    defaultValidationErrors: Partial<AbpValidationError>[] = [
        { name: 'required', localizationKey: 'ThisFieldIsRequired' },
        {
            name: 'minlength',
            localizationKey: 'PleaseEnterAtLeastNCharacter',
            propertyKey: 'requiredLength',
        },
        {
            name: 'maxlength',
            localizationKey: 'PleaseEnterNoMoreThanNCharacter',
            propertyKey: 'requiredLength',
        },
        {
            name: 'email',
            localizationKey: 'InvalidEmailAddress',
        },
        {
            name: 'pattern',
            localizationKey: 'InvalidPattern',
            propertyKey: 'requiredPattern',
        },
        {
            name: 'validateEqual',
            localizationKey: 'PairsDoNotMatch',
        },
        {
            name: 'CapValidator',
            localizationKey: 'CapNotValid',
        },
        {
            name: 'FiscalCodeValidator',
            localizationKey: 'FiscalCodeNotValid',
        },
        {
            name: 'VatCodeValidator',
            localizationKey: 'VatCodeNotValid',
        },
        {
            name: 'FeePerHourValidator',
            localizationKey: 'FeePerHourNotValid',
        },
        {
            name: 'FeePerHourItValidator',
            localizationKey: 'FeePerHourItNotValid',
        },
        {
            name: 'FeePerHourEnValidator',
            localizationKey: 'FeePerHourEnNotValid',
        },
        {
            name: 'UserRolesValidator',
            localizationKey: 'AtLeastOneRole',
        },
        {
            name: 'DayRangeValidator',
            localizationKey: 'DayPeriodNotValid',
        },
        {
            name: 'WeekRangeValidator',
            localizationKey: 'WeekPeriodNotValid',
        },
        {
            name: 'MonthRangeValidator',
            localizationKey: 'MonthPeriodNotValid',
        },
        {
            name: 'MissingFieldValidator',
            localizationKey: 'MissingField',
        },
        {
            name: 'missingReference',
            localizationKey: 'MissingReference',
        },
        {
            name: 'DayValidator',
            localizationKey: 'AtLeastOneDay',
        },
        {
            name: 'invalidDate',
            localizationKey: 'Invalid',
        },
        {
            name: 'hourNotValid',
            localizationKey: 'HourNotValid',
        },
        {
            name: 'notValidTimeRange',
            localizationKey: 'TimeRangesNotValid',
        },
        {
            name: 'duplicatedEmail',
            localizationKey: 'IdentityDuplicateEmail',
        },
        {
            name: 'duplicatedDeletedEmail',
            localizationKey: 'IdentityDuplicateEmailDeleted',
        },
        {
            name: 'TopicValidator',
            localizationKey: 'TopicValidatorMessage',
        },
        {
            name: 'notValidFundAmount',
            localizationKey: 'NotValidFundAmount',
        },
        {
            name: 'duplicatedServiceArea',
            localizationKey: 'DuplicatedServiceArea',
        },
        {
            name: 'duplicatedServiceReport',
            localizationKey: 'DuplicatedServiceReport',
        },
        {
            name: 'TimeValidator',
            localizationKey: 'TimeValidator',
        },
        {
            name: 'max',
            localizationKey: 'MaxNumberValidator',
        },
        {
            name: 'min',
            localizationKey: 'MinNumberValidator',
        },
        {
            name: 'minDate',
            localizationKey: 'MinDateValidator',
        },
        {
            name: 'maxDate',
            localizationKey: 'MaxDateValidator',
        },
    ];
    validationErrors = <AbpValidationError[]>this.defaultValidationErrors;

    @Input() control: AbstractControl;
    @Input() controlEl: HTMLElement;

    private subscription: Subscription = new Subscription();

    constructor(injector: Injector, public _renderer: Renderer2) {
        super(injector);
    }

    @Input() set customValidationErrors(val: AbpValidationError[]) {
        if (val && val.length > 0) {
            const defaults = this.defaultValidationErrors.filter(
                (defaultValidationError) => !val.find((customValidationError) => customValidationError.name === defaultValidationError.name)
            );
            this.validationErrors = <AbpValidationError[]>[...defaults, ...val];
        }
    }

    ngOnInit() {
        if (this.controlEl) {
            const sub = this.control.statusChanges.subscribe(() => {
                if (this.control.valid) {
                    this._renderer.removeClass(this.controlEl, 'is-invalid');
                }
            });
            this.subscription.add(sub);
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    getValidationErrorMessage(error: AbpValidationError): string {
        if (this.controlEl) {
            this._renderer.addClass(this.controlEl, 'is-invalid');
        }

        const propertyValue = this.control.errors[error.name][error.propertyKey];
        return !!propertyValue ? this.l(error.localizationKey, propertyValue) : this.l(error.localizationKey);
    }
}
