<!--START SERVICE ACTIONS-->
<div class="d-flex">
    <button type="button" class="btn btn-default btn-sm mx-1" (click)="seeServiceDetail(operatorService.service)" [title]="'Details' | localize">
        <em class="fas fa-eye"></em>
        <span class="sr-only"> {{ 'Details' | localize }} </span>
    </button>

    <button
        *ngIf="showCalendar"
        type="button"
        class="btn btn-default btn-sm mx-1 float-right"
        [title]="'SeeServiceOperatorScheduled' | localize"
        (click)="navigateToServiceOperatorScheduled()"
    >
        <em class="fas fa-calendar"></em>
        <span class="sr-only"> {{ 'SeeServiceOperatorScheduled' | localize }} </span>
    </button>

    <button
        type="button"
        class="btn btn-default btn-sm mx-1"
        *ngIf="isGranted(permissionsConst.Service_Edit)"
        (click)="editService(operatorService.service)"
        [title]="'Edit' | localize"
    >
        <em class="fas fa-pencil-alt text-success"></em>
        <span class="sr-only"> {{ 'Edit' | localize }} </span>
    </button>
</div>
<!--END SERVICE ACTIONS-->
