<form [formGroup]="formGroup" (ngSubmit)="save()">
    <div class="row">
        <input type="hidden" formControlName="id" />

        <div class="form-group mb-0 col-md-2">
            <label class="col-form-label" [for]="'name' + formGroup.value.id">
                {{ 'Name' | localize }}
            </label>
            <div *ngIf="controls.id.value"><h6>{{ controls.name.value | localize }}</h6></div>
            <div *ngIf="!controls.id.value">
                <input type="text"
                       class="form-control form-control-sm"
                       name="name"
                       [id]="'name' + formGroup.value.id"
                       formControlName="name"
                       [readonly]="controls.id.value"
                       #nameEl />
                <abp-validation-summary [control]="controls.name" [controlEl]="nameEl"></abp-validation-summary>
            </div>
        </div>

        <div class="form-group mb-0 col-md-2">
            <label class="col-form-label" [for]="'quantity' + formGroup.value.id">
                {{ 'HRRequestQuantity' | localize }}
            </label>
            <div *ngIf="controls.id.value" class="form-group col-md-4 text-center"><h6>{{ controls.quantity.value | localize }}</h6></div>
            <div *ngIf="!controls.id.value">
                <input type="text"
                       class="form-control form-control-sm"
                       [id]="'quantity' + formGroup.value.id"
                       formControlName="quantity"
                       [readonly]="controls.id.value"
                       #quantityEl />
                <abp-validation-summary [control]="controls.quantity" [controlEl]="quantityEl"></abp-validation-summary>
            </div>
        </div>

        <div class="form-group mb-0 col-md-2" *ngIf="controls.id.value" >
            <label class="col-form-label" [formGroup]="formGroup.get('currencyType')">
                {{ 'HRRequestCurrencyType' | localize }}
            </label>
            <div class="form-group col-md-4 text-center"><h6>{{ controls.currencyType.value | currencySymbol }}</h6></div>
        </div>

        <div class="form-group mb-0 col-md-2">
            <label class="col-form-label" [for]="'unitPrice' + formGroup.value.id">
                {{ 'HRRequestUnitPrice' | localize }}
            </label>
            <div *ngIf="controls.id.value" class="form-group col-md-4 text-center"><h6>{{ controls.unitPrice.value | localize }}</h6></div>
            <div *ngIf="!controls.id.value">
                <input type="text"
                       class="form-control form-control-sm"
                       [id]="'unitPrice' + formGroup.value.id"
                       formControlName="unitPrice"
                       [readonly]="controls.id.value"
                       #unitPriceEl />
                <abp-validation-summary [control]="controls.unitPrice" [controlEl]="unitPriceEl"></abp-validation-summary>
            </div>
        </div>

        <div class="form-group mb-0 col-md-2" *ngIf="controls.id.value">
            <label class="col-form-label" [for]="'totalPrice' + formGroup.value.id">
                {{ 'HRRequestTotalPrice' | localize }}
            </label>
            <div *ngIf="controls.id.value" class="form-group col-md-4 text-center"><h6>{{ controls.totalPrice.value | localize }}</h6></div>
            <div *ngIf="!controls.id.value">
                <input type="text"
                       class="form-control form-control-sm"
                       [id]="'totalPrice' + formGroup.value.id"
                       formControlName="totalPrice"
                       [readonly]="!controls.id.value"
                       #totalPriceEl />
                <abp-validation-summary [control]="controls.totalPrice" [controlEl]="totalPriceEl"></abp-validation-summary>
            </div>
        </div>

        <div class="form-group mb-0 text-right col-md" >
            <span class="col-form-label d-block">&nbsp;</span>

            <button class="btn btn-default btn-sm mx-1"
                    type="button"
                    [disabled]="!formGroup.dirty && controls.id.value != '0'"
                    (click)="cancelActions()"
                    [title]="'Cancel' | localize">
                <em class="fas fa-undo"></em>
                <span class="sr-only"> {{ 'Cancel' | localize }} </span>
            </button>

            <button class="btn btn-default btn-sm mx-1" [disabled]="!formGroup.dirty" [title]="'Save' | localize">
                <em class="fas fa-save text-success"></em>
                <span class="sr-only"> {{ 'Save' | localize }}</span>
            </button>

            <button *ngIf="controls.id.value"
                    class="btn btn-default btn-sm mx-1"
                    type="button"
                    [disabled]="formGroup.disabled || hrrequestState"
                    (click)="deleteSupplyingObject()"
                    [title]="'Delete' | localize">
                <em class="fas fa-trash-alt text-danger"></em>
                <span class="sr-only"> {{ 'Delete' | localize }}</span>
            </button>

        </div>
    </div>
</form>
