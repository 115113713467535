<ng-container *ngIf="control.invalid && (control.dirty || control.touched)">
    <ng-container *ngFor="let validationError of validationErrors">
        <span
            *ngIf="this.control.errors && !!this.control.errors[validationError.name]"
            class="error invalid-feedback"
            [class.d-block]="!!this.control.errors[validationError.name]"
        >
            {{ getValidationErrorMessage(validationError) }}
        </span>
    </ng-container>
</ng-container>
