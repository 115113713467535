import { Component, Input, OnInit } from '@angular/core';
import { SummaryDto } from '@shared/service-proxies/service-proxies';

@Component({
    selector: 'progress-tracking',
    templateUrl: './progress-tracking-component.html',
    styleUrls: ['./progress-tracking-component.scss'],
})
export class ProgressTrackingComponent implements OnInit {
    @Input()
    summary?: SummaryDto;

    constructor() {}

    ngOnInit(): void {}
}
