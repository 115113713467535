import { Component, Injector, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';

import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { PagedListingComponentBase, PagedRequestDto } from '@shared/paged-listing-component-base';
import { ScheduledNotificationDto, ScheduledNotificationServiceProxy } from '@shared/service-proxies/service-proxies';

class PagedNotificationsRequestDto extends PagedRequestDto {
    keyword: string;
    isActive: boolean | null;
}

@Component({
    selector: 'scheduled-notification-list',
    templateUrl: './scheduled-notification-list.component.html',
})
export class ScheduledNotificationListComponent extends PagedListingComponentBase<any> implements OnInit, OnChanges, OnDestroy {
    @Input() entityId: number;
    @Input() entityType: string;

    keyword = '';
    isActive: boolean | null;
    isAddNotificationActive: boolean;
    scheduledNotifications: ScheduledNotificationDto[];

    private subscription: Subscription = new Subscription();

    constructor(private injector: Injector, private notificationService: ScheduledNotificationServiceProxy) {
        super(injector);

        this.totalItems = 0;
    }

    protected list(request: PagedNotificationsRequestDto, pageNumber: number, finishedCallback: Function): void {
        request.keyword = this.keyword;
        request.isActive = this.isActive;

        const sub = this.notificationService
            .getAll(this.entityId, this.entityType, request.skipCount, request.maxResultCount)
            .pipe(
                finalize(() => {
                    finishedCallback();
                })
            )
            .subscribe((result) => {
                this.scheduledNotifications = result.items;
                this.showPaging(result, pageNumber);
            });
        this.subscription.add(sub);
    }

    protected delete(entity: any): void {}

    ngOnInit(): void {}

    ngOnChanges(changes: SimpleChanges) {
        this.refresh();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    addNotification() {
        this.isAddNotificationActive = !this.isAddNotificationActive;
    }

    updateList() {
        this.refresh();
        this.isAddNotificationActive = false;
    }
}
