import { Component, Injector, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponentBase } from '@shared/app-component-base';
import { UserDto, UserServiceProxy } from '@shared/service-proxies/service-proxies';

@Component({
    selector: 'user-item',
    templateUrl: './user-item.component.html',
    styles: [],
})
export class UserItemComponent extends AppComponentBase {
    @Input() user: UserDto;
    @Input() withEntityTypeIcon = false;
    @Input() withEntityTypeBadge = false;
    constructor(public userService: UserServiceProxy, private router: Router, private activatedRoute: ActivatedRoute, injector: Injector) {
        super(injector);
    }

    goOnUserDetail() {
        if (this.isGranted(this.permissionsConst.User_Detail)) this.router.navigate(['app/user', this.user.id, 'detail'], { relativeTo: this.activatedRoute.root });
    }
}
