import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContractDto } from '@shared/service-proxies/service-proxies';

@Component({
    selector: 'contract-basic-item',
    templateUrl: './contract-basic-item.component.html',
    styles: [],
})
export class ContractBasicItemComponent implements OnInit {
    @Input() contract: ContractDto;

    constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

    ngOnInit(): void {}

    showContractDetail() {
        this.router.navigate(['app/contract', this.contract?.id, 'detail'], { relativeTo: this.activatedRoute.root });
    }
}
