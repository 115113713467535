import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponentBase } from '@shared/app-component-base';
import { ServiceDto, ServiceOperatorDto } from '@shared/service-proxies/service-proxies';

@Component({
    selector: 'service-actions-item',
    templateUrl: './service-actions-item.component.html',
    styles: [],
})
export class ServiceActionsItemComponent extends AppComponentBase implements OnInit {
    @Input() operatorService: ServiceOperatorDto;
    @Input() showCalendar: boolean = false;
    @Output() goToServiceOperatorScheduledEvent: EventEmitter<number> = new EventEmitter<number>();

    constructor(private injector: Injector, private router: Router, private activatedRoute: ActivatedRoute) {
        super(injector);
    }

    ngOnInit(): void {}

    seeServiceDetail(service) {
        this.router.navigate(['app/service', service.id, 'detail'], { relativeTo: this.activatedRoute.root });
    }

    editService(service) {
        this.router.navigate(['app/service', service.id, 'edit'], { relativeTo: this.activatedRoute.root });
    }

    navigateToServiceOperatorScheduled() {
        this.goToServiceOperatorScheduledEvent.emit(this.operatorService.service.id);
    }
}
