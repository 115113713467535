import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AddressDto, MunicipalityServiceProxy } from '@shared/service-proxies/service-proxies';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { Observable, Observer, of, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ValidatorsHelper } from '@shared/helpers/ValidatorsHelper';

@Component({
    selector: 'address-shared',
    templateUrl: './address.component.html',
    styleUrls: ['./address.component.scss'],
})
export class AddressComponent implements OnChanges {
    @Input() formGroup: FormGroup;
    @Input() isEditing: boolean;
    @Input() addressDto: AddressDto;

    selectedMunicipality: string;
    municipalitiesObs$: Observable<any>;
    showAddressForm: boolean;

    get controls() {
        return this.formGroup.controls;
    }

    constructor(private municipalityService: MunicipalityServiceProxy) {}

    ngOnChanges() {
        if (this.addressDto && this.isEditing) {
            this.createAddressFormGroup();
            this.showAddressForm = true;
        }
    }

    /**
     * TYPEAHEAD CODE
     * send a rest call when the municipality reach 2 character length,
     * to obtain municipality filtered list
     * @param event
     */
    getMunicipalitiesOnInput(event) {
        const asyncSelected = event.target.value;
        this.controls.address.get('municipality').get('id').setValue(null);

        this.municipalitiesObs$ = new Observable((observer: Observer<string>) => {
            observer.next(asyncSelected);
        }).pipe(
            switchMap((query) => {
                if (query && query.length > 1) {
                    return this.municipalityService.getAllByKeyword(query, undefined).pipe(map((data) => (data && data) || []));
                }
                return of([]);
            })
        );
    }

    typeAheadOnSelect(e: TypeaheadMatch): void {
        this.controls.address.get('municipality').patchValue(e.item);
    }

    delete() {
        this.formGroup.removeControl('address');
        this.addressDto = null;
        this.showAddressForm = false;
    }

    openWindowAddress() {
        this.createAddressFormGroup();
        this.showAddressForm = true;
    }

    createAddressFormGroup() {
        const addressFormGroup = new FormGroup({
            name: new FormControl('', Validators.required),
            description: new FormControl('', Validators.required),
            postalCode: new FormControl('', [ValidatorsHelper.capRegexValidator, Validators.maxLength(6), Validators.required]),
            isMain: new FormControl(true),
            municipality: new FormGroup({
                name: new FormControl('', Validators.required),
                isChiefTown: new FormControl(),
                province: new FormControl(''),
                id: new FormControl('', Validators.required),
            }),
            id: new FormControl(0),
        });
        if (this.addressDto) addressFormGroup.patchValue(this.addressDto);
        this.formGroup.addControl('address', addressFormGroup);
        this.controls.address.get('municipality').get('id').markAsDirty();
        this.controls.address.get('municipality').get('id').markAsTouched();
    }
}
