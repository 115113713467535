<div class="form-group row mb-2">
    <label class="col-md-3 col-form-label" for="contractType">
        {{ 'UserContractType' | localize }}
    </label>

    <div class="col-md-9 mb-2" [formGroup]="formGroup">
        <input type="hidden" formControlName="id" />
        <div class="input-group">
            <div class="input-group-prepend">
                <span class="input-group-text" [tooltip]="'WorkContractTypeTypeaheadHelper' | localize">
                    <em class="fas fa-info"></em>
                </span>
            </div>
            <input
                type="text"
                class="form-control"
                id="contractType"
                [placeholder]="'SearchWithThreeDot' | localize"
                (input)="getContractTypesOnInput($event)"
                [typeaheadItemTemplate]="contractTypesTemplate"
                (typeaheadOnSelect)="typeAheadOnSelect($event)"
                [typeahead]="workContractTypeObs$"
                [typeaheadAsync]="true"
                [isAnimated]="true"
                typeaheadWaitMs="400"
                formControlName="description"
                typeaheadOptionField="contractType.description"
                autocomplete="off"
            />
            <div class="input-group-append" *ngIf="isSearchingContractType">
                <span class="input-group-text">
                    <em class="fas fa-spinner fa-spin"></em>
                </span>
            </div>
        </div>
        <ng-template #contractTypesTemplate let-contractType="item" class="dropdown-municipalities" let-index="index">
            {{ contractType.description.toString() | titlecase }}
        </ng-template>
    </div>
</div>
