import { Component, Injector, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { PagedListingComponentBase, PagedRequestDto } from '@shared/paged-listing-component-base';
import { ServiceDto, ServiceDtoPagedResultDto, ServiceServiceProxy } from '@shared/service-proxies/service-proxies';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

class PagedContractRequestDto extends PagedRequestDto {
    keyword: string;
    isActive: boolean | null;
}

@Component({
    selector: 'contract-service-list',
    templateUrl: './contract-service-list.component.html',
    animations: [appModuleAnimation()],
})
export class ContractServiceListComponent extends PagedListingComponentBase<ServiceDto> implements OnInit, OnChanges, OnDestroy {
    @Input() contractId: number;
    @Input() customerId: number;

    private subscription: Subscription = new Subscription();
    keyword = '';
    isActive: boolean | null;

    services: ServiceDto[];

    constructor(private injector: Injector, private router: Router, private activatedRoute: ActivatedRoute, private serviceService: ServiceServiceProxy) {
        super(injector);
    }

    protected list(request: PagedContractRequestDto, pageNumber: number, finishedCallback: Function): void {
        request.skipCount = !isNaN(request.skipCount) ? request.skipCount : 0;
        request.keyword = this.keyword;
        request.isActive = this.isActive;

        const sub = this.serviceService
            .getAll(this.contractId, this.customerId, undefined, undefined, request.keyword, request.skipCount, request.maxResultCount)
            .pipe(
                finalize(() => {
                    finishedCallback();
                })
            )
            .subscribe((result: ServiceDtoPagedResultDto) => {
                this.services = result.items;
                this.showPaging(result, pageNumber);
            });
        this.subscription.add(sub);
    }

    protected delete(): void {}

    ngOnInit(): void {}

    ngOnChanges() {
        this.refresh();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    showDetails(service) {
        this.router.navigate(['app/service', service.id, 'detail'], { relativeTo: this.activatedRoute.root });
    }

    createService() {
        if (this.contractId) this.router.navigate(['app/service', this.contractId, '0', 'create'], { relativeTo: this.activatedRoute.root });
        if (this.customerId) this.router.navigate(['app/service/0', this.customerId, 'create'], { relativeTo: this.activatedRoute.root });
    }
}
