import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponentBase } from '@shared/app-component-base';
import { FeatureConsts } from '@shared/FeatureConsts';
import { ServiceDto, ServiceServiceProxy } from '@shared/service-proxies/service-proxies';
import { finalize } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
    selector: 'service-item',
    templateUrl: './service-item.component.html',
    styles: [],
})
export class ServiceItemComponent extends AppComponentBase implements OnInit {
    @Input() service: ServiceDto;
    @Input() withEntityTypeIcon = false;
    @Input() withEntityTypeBadge = false;
    @Input() withContract = this.feature.isEnabled(FeatureConsts.Contracts);
    @Input() withCustomer = this.feature.isEnabled(FeatureConsts.Customers);
    @Input() withActions = true;

    @Output() goToServiceOperatorScheduledEvent: EventEmitter<number> = new EventEmitter<number>();
    @Output() refreshList = new EventEmitter();

    isEnabledFeatureContracts: boolean = this.feature.isEnabled(FeatureConsts.Contracts);
    isEnabledFeatureCustomers: boolean = this.feature.isEnabled(FeatureConsts.Customers);

    private subscription: Subscription = new Subscription();

    constructor(private injector: Injector, private router: Router, private activatedRoute: ActivatedRoute, private serviceService: ServiceServiceProxy) {
        super(injector);
    }

    ngOnInit(): void {}

    navigateToServiceOperatorScheduled() {
        this.goToServiceOperatorScheduledEvent.emit(this.service.id);
    }

    delete(service) {
        abp.message.confirm(this.l('ServiceDeleteAssociatedObjectWarningMessage', service.description), '', (result: boolean) => {
            if (result) {
                this.startLoading();
                const sub = this.serviceService
                    .delete(service.id)
                    .pipe(finalize(() => {}))
                    .subscribe(() => {
                        this.stopLoading();
                        this.notify.info(this.l('DeletedSuccessfully'));
                        this.refreshList.emit();
                    });

                this.subscription.add(sub);
            }
        });
    }
}
