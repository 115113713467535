import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'fileSize',
    pure: true,
})
export class FileSizePipe implements PipeTransform {
    transform(size: number) {
        const result = this.formatBytes(size, 0);
        return result;
    }

    private formatBytes(bytes: number, decimals: number = 2) {
        if (bytes === 0) {
            return '0 Bytes';
        }

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
}
