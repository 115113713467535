<div *ngIf="hrrequestApprovalFlow" class="d-flex align-items-center justify-content-between">
    <span>
        <!-- START HRREQUEST-TYPE -->
        <span class="small text-left d-inline-block mx-4" *ngIf="hrrequestApprovalFlow.hrRequestType">
            <span class="text-uppercase text-muted">{{ 'HRRequestType' | localize }}<br /></span>
            {{ hrrequestApprovalFlow.hrRequestType.description | localize }}
        </span>
        <!-- END HRREQUEST-TYPE -->

        <!-- START ORGANIZATION-UNITS  -->
        <span class="small text-left d-inline-block mx-4" *ngIf="hrrequestApprovalFlow.hrRequestType">
            <span class="text-uppercase text-muted">{{ 'OrganizationUnit' | localize }}<br /></span>
            <organization-unit-basic-item [organizationUnit]="hrrequestApprovalFlow.organizationUnit"></organization-unit-basic-item>
        </span>
        <!-- END ORGANIZATION-UNITS -->
    </span>

    <!--START HRREQUEST ACTIONS-->
    <span class="mx-1 justify-content-end">
        <button type="button" class="btn btn-default btn-sm mx-1" (click)="seeHRRequestApprovalFlowDetail()" [title]="'Details' | localize">
            <em class="fas fa-eye"></em>
            <span class="sr-only"> {{ 'Details' | localize }} </span>
        </button>

        <button type="button" class="btn btn-default btn-sm mx-1" (click)="deleteHRRequestApprovalFlow()" [title]="'Delete' | localize">
            <em class="fas fa-trash-alt text-danger"></em>
            <span class="sr-only"> {{ 'Delete' | localize }} </span>
        </button>
    </span>
    <!--END HRREQUEST ACTIONS-->
</div>
