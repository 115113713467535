import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UserDto } from '@shared/service-proxies/service-proxies';

@Component({
    selector: 'user-delete-item',
    templateUrl: './user-delete-item.component.html',
    styles: [],
})
export class UserDeleteItemComponent {
    @Input() user: UserDto;
    @Input() isCancelButtonBlocked: boolean = false;
    @Output() getItemToDelete: EventEmitter<UserDto> = new EventEmitter<UserDto>();

    constructor() {}

    delete() {
        this.getItemToDelete.emit(this.user);
    }
}
