<div class="row my-2">
    <div class="col-12">
        <small class="text-muted text-uppercase">{{ 'Objectives' | localize }}</small>
    </div>
</div>
<div class="row my-2">
    <div class="col-12">
        <progressbar [max]="100" [value]="summary?.outputsPercentage || 0">
            <span class="text-nowrap">{{summary?.outputsPercentage || 0}} %</span>
        </progressbar>
    </div>
</div>

<div class="row my-2">
    <div class="col-12">
        <small class="text-muted text-uppercase">{{ 'Costs' | localize }}</small>
    </div>
</div>
<div class="row my-2">
    <div class="col-12">
        <progressbar [max]="100" [value]="summary?.amountPercentage || 0">
            <span class="text-nowrap">{{(summary?.amountPercentage || 0)}} %</span>
        </progressbar>
    </div>
</div>

<div class="row my-2">
    <div class="col-12">
        <small class="text-muted text-uppercase">{{ 'Duration' | localize }}</small>
    </div>
</div>
<div class="row my-2">
    <div class="col-12">
        <progressbar [max]="100" [value]="summary?.durationPercentage || 0">
            <span class="text-nowrap">{{summary?.durationPercentage || 0}} %</span>
        </progressbar>
    </div>
</div>
