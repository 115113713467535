<div [@routerTransition]>
    <section class="content-header" *ngIf="!externalView">
        <div class="container-fluid">
            <div class="row">
                <div class="col-6">
                    <h1>{{ 'ApprovalFlows' | localize }}</h1>
                </div>
                <div class="col-6 text-right">
                    <a class="btn bg-blue" (click)="createApprovalFlow()">
                        <em class="fa fa-plus mr-2"></em>
                        {{ 'Create' | localize }}
                    </a>
                </div>
            </div>
        </div>
    </section>
    <section class="mb-3">
        <div class="card">
            <div class="card-header" *ngIf="externalView">
                <div class="col-lg-12">
                    <div class="d-flex flex-row align-items-center justify-content-between mb-2">
                        <h4 class="m-0">{{ 'ApprovalFlows' | localize }}</h4>

                        <button type="button" class="btn btn-default btn-sm bg-blue" (click)="createApprovalFlow()">
                            <em class="fa fa-plus mr-2"></em>
                            {{ 'Create' | localize }}
                        </button>
                    </div>
                </div>
            </div>
            <div class="card-header bg-light" *ngIf="!externalView">
                <div class="justify-content-between">
                    <form class="row" [formGroup]="form">
                        <div [formGroup]="form.get('hrRequestType')" class="col-5">
                            <label class="d-block">{{ 'HRRequestType' | localize }}</label>
                            <ng-select
                                class="w-100"
                                [items]="hrrequestData"
                                bindLabel="description"
                                bindValue="description"
                                [multiple]="false"
                                dropdownPosition="auto"
                                bindValue="id"
                                labelForId="id"
                                [searchable]="false"
                                [hideSelected]="true"
                                [closeOnSelect]="true"
                                [placeholder]="'SelectHRRequestType' | localize"
                                [virtualScroll]="true"
                                formControlName="id"
                            >
                                <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                                    <medium> {{ item.description | localize }}</medium>
                                </ng-template>
                            </ng-select>
                        </div>
                        <div [formGroup]="form.get('organizationUnit')" class="col-5">
                            <label class="d-block">{{ 'EmployersGroup' | localize }}</label>
                            <ng-select
                                class="w-100"
                                [items]="organizationUnits$ | async"
                                bindLabel="displayName"
                                [multiple]="false"
                                dropdownPosition="auto"
                                bindValue="id"
                                labelForId="id"
                                [hideSelected]="true"
                                [closeOnSelect]="true"
                                [placeholder]="'SelectEmployersGroup' | localize"
                                [virtualScroll]="true"
                                formControlName="id"
                            >
                                <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                                    <medium> {{ item.displayName }}</medium>
                                </ng-template>
                            </ng-select>
                        </div>
                        <div class="col-2 d-flex align-items-end flex-row-reverse">
                            <button type="button" class="btn btn-primary" (click)="getDataPage(1)">
                                <em class="fas fa-search"></em>
                                {{ 'Search' | localize }}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <div class="card-body">
                <div
                    *ngFor="
                        let hRRequestTypeOrganizationUnit of hRRequestTypeOrganizationUnits
                            | paginate
                                : {
                                      id: 'hRRequestTypeOrganizationUnitsListPaged',
                                      itemsPerPage: pageSize,
                                      currentPage: pageNumber,
                                      totalItems: totalItems
                                  };
                        let i = index
                    "
                >
                    <hrrequest-approvation-flow-item [hrrequestApprovalFlow]="hRRequestTypeOrganizationUnit" (refreshList)="refresh()"></hrrequest-approvation-flow-item>
                    <hr *ngIf="i < hRRequestTypeOrganizationUnits.length - 1" />
                </div>

                <div *ngIf="hRRequestTypeOrganizationUnits?.length == 0" class="my-2 text-center">
                    <span>{{ 'NoApprovalFlowFound' | localize }}</span>
                </div>
            </div>

            <div class="card-footer bg-light border-top">
                <div class="row">
                    <div class="col-sm-4 col-12 text-sm-left text-center">
                        <button class="btn btn-default btn-sm" (click)="refresh()">
                            <em class="fas fa-sync mr-2"></em>
                            {{ 'Refresh' | localize }}
                        </button>
                    </div>
                    <div class="col-sm-4 col-12 text-center">
                        <p class="mb-0 my-2">
                            {{ 'TotalRecordsCount' | localize: totalItems }}
                        </p>
                    </div>
                    <div class="col-sm-4 col-12">
                        <div class="float-sm-right m-auto">
                            <abp-pagination-controls id="hRRequestTypeOrganizationUnitsListPaged" (pageChange)="getDataPage($event)"></abp-pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
