import { Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { forkJoin, Observable, Observer, of, Subscription } from 'rxjs';
import { finalize, map, switchMap } from 'rxjs/operators';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';

import { AppComponentBase } from '@shared/app-component-base';
import {
    BaseUserDto,
    CreateScheduledNotificationDto,
    CreateScheduledNotificationUserDto,
    ScheduledNotificationDto,
    ScheduledNotificationServiceProxy,
    ScheduledNotificationUserServiceProxy,
    UserDto,
    UserLoginInfoDto,
    UserServiceProxy,
} from '@shared/service-proxies/service-proxies';
import { AppSessionService } from '@shared/session/app-session.service';

@Component({
    selector: 'scheduled-notification',
    templateUrl: './scheduled-notification.component.html',
    styles: [],
})
export class ScheduledNotificationComponent extends AppComponentBase implements OnInit, OnDestroy {
    @Input() isNew: boolean;
    @Input() notification: ScheduledNotificationDto;

    @Input() entityId: number;
    @Input() entityType: string;

    @Output() updateList = new EventEmitter<void>();

    get notificationId() {
        return this.notification?.id ?? 0;
    }

    get controls() {
        return this.form.controls;
    }

    private subscription: Subscription = new Subscription();

    form: FormGroup;

    usersList: (BaseUserDto | UserDto | UserLoginInfoDto)[] = [];
    usersListIds: number[] = [];

    usersListToAdd: CreateScheduledNotificationUserDto[] = [];
    usersListToDelete: number[] = [];

    usersObs$: Observable<BaseUserDto[]>;
    selectedUser: string;
    user: UserLoginInfoDto;
    isSearchingUser: boolean = false;

    bsDatepickerConfig = {
        dateInputFormat: 'DD/MM/YYYY',
        showTodayButton: true,
        startView: 'day',
        todayPosition: 'center',
        useUtc: false,
        showClearButton: true,
    };

    constructor(
        private injector: Injector,
        private userService: UserServiceProxy,
        private notificationService: ScheduledNotificationServiceProxy,
        private relationNotificationUser: ScheduledNotificationUserServiceProxy,
        private activatedRoute: ActivatedRoute,
        private sessionService: AppSessionService
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.form = new FormGroup({
            id: new FormControl(0),
            notificationName: new FormControl('', Validators.required),
            description: new FormControl(''),
            dueDate: new FormControl('', [Validators.required]),
            userIds: new FormControl({ value: '' }),
            sendToAll: new FormControl({ value: false }),
            entityId: new FormControl(this.entityId),
            entityType: new FormControl(this.entityType),
        });

        if (this.activatedRoute.snapshot.params) {
            this.user = new UserLoginInfoDto(this.sessionService.user);
            if (this.isNew) {
                this.usersList.push(this.user);

                this.form.controls['sendToAll'].disable();
                this.form.controls['sendToAll'].setValue(false);
            }
        }

        if (!this.isNew && this.notification) {
            this.form.patchValue(this.notification);
            if (this.notification.dueDate) {
                this.form.get('dueDate').setValue(this.notification.dueDate.toDate());
            }
            const sendToAllIsActive = this.notification.users.length == 0;
            if (sendToAllIsActive) this.form.get('sendToAll').enable();
            else this.form.get('sendToAll').disable();
            this.form.get('sendToAll').setValue(sendToAllIsActive);

            this.usersList = [];
            this.usersList = [...this.notification.users.map((u) => u.user)];
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    reset(): void {
        this.updateList.emit();
    }

    /**
     * SAVE (CREATE/UPDATE) CODE
     * CASE CREATE:
     *   a userIdsList is mapped from usersList, if empty ids field must be null
     * CASE UPDATE:
     *   there are three API calls:
     *   1. update of fields name, description, date
     *   2. add new user (create relationship between this scheduled notifications and user) if not already
     *      present inside the users list
     *   3. delete user (delete relationship between this scheduled notifications and user), with a list
     *      previously filtered to avoid maximum effort (inside getItemToDelete(userObject))
     *
     *   so actions 'add/delete user' start only on save, while lists optimization are hidden inside fe.
     * @param void
     */
    save() {
        if (this.isNew) {
            const notificationObject = new CreateScheduledNotificationDto(this.form.value);
            const usersIds = this.usersList.map((user) => user.id);
            if (usersIds.length > 0) notificationObject.userIds = usersIds;
            else notificationObject.userIds = null;
            this.startLoading();
            const sub = this.notificationService
                .create(notificationObject)
                .pipe(finalize(() => {}))
                .subscribe(() => {
                    this.stopLoading();
                    this.notify.info(this.l('SavedSuccessfully'));
                    this.updateList.emit();
                });
            this.subscription.add(sub);
        } else {
            const notificationObject = new ScheduledNotificationDto(this.form.value);

            this.startLoading();
            const sub = this.notificationService
                .update(notificationObject)
                .pipe(
                    switchMap(() => {
                        let observableCreate = [];

                        if (this.usersListToAdd.length > 0)
                            this.usersListToAdd.forEach((objCreate) => {
                                observableCreate.push(
                                    this.relationNotificationUser
                                        .create(objCreate)
                                        .pipe(finalize(() => {}))
                                        .subscribe()
                                );
                            });

                        if (this.usersListToDelete.length > 0)
                            this.usersListToDelete.forEach((userId) => {
                                observableCreate.push(
                                    this.relationNotificationUser
                                        .delete(userId)
                                        .pipe(finalize(() => {}))
                                        .subscribe(() => {})
                                );
                            });

                        if (this.usersListToAdd.length > 0 || this.usersListToDelete.length > 0)
                            return forkJoin({
                                objectList: observableCreate,
                            });
                        else {
                            return of({});
                        }
                    })
                )
                .subscribe(() => {
                    this.usersListToAdd = [];
                    this.usersListToDelete = [];
                    this.stopLoading();
                    this.notify.info(this.l('SavedSuccessfully'));
                    this.updateList.emit();
                });
            this.subscription.add(sub);
        }
    }

    /**
     * TYPEAHEAD CODE
     * send a rest call when the area reach 2 character length,
     * to obtain area filtered list
     * @param event
     */
    getUsersOnInput(event) {
        const asyncSelected = event.target.value;

        this.usersObs$ = new Observable((observer: Observer<string>) => {
            observer.next(asyncSelected);
        }).pipe(
            switchMap((query) => {
                this.isSearchingUser = false;
                if (query) {
                    this.isSearchingUser = true;
                    return this.userService.getUsersByKeyword(query).pipe(
                        map((data) => (data && data) || []),
                        finalize(() => {
                            this.isSearchingUser = false;
                        })
                    );
                }
                return of([] as BaseUserDto[]);
            })
        );
    }

    /**
     * TYPEAHEAD SELECT CODE
     * once a user is selected, list is filtered to avoid duplicate users
     * @param event
     */
    typeAheadOnSelect(e: TypeaheadMatch): void {
        this.selectedUser = '';

        var duplicate = false;
        this.usersList.forEach((el) => {
            if (el.id == e.item.id) {
                duplicate = true;
            }
        });

        if (!duplicate) {
            this.form.controls['sendToAll'].disable();
            const newUser = new BaseUserDto(e.item);

            this.usersList.push(newUser);

            if (!this.isNew) {
                const newRelationData = new CreateScheduledNotificationUserDto({ scheduledNotificationId: this.notification.id, userId: e.item.id });
                this.usersListToAdd.push(newRelationData);
            }
        }
    }

    /**
     * DELETE ITEM CODE
     * when a user is deleted from trash button, it's necessary to check if there are all conditions
     * to add user is usersListToDelete
     * (ex. if deleted after added but without saving, there' no need
     * to send the delete request API)
     * @param userObject
     */
    getItemToDelete(userObject) {
        const indexArray = this.usersList.findIndex((el) => el.id == userObject.id);
        if (!this.isNew) {
            const userRelationArray = this.notification.users.find((el) => el.user.id == userObject.id);
            if (indexArray !== -1) {
                this.usersList.splice(indexArray, 1);
                if (userRelationArray?.id) this.usersListToDelete.push(userRelationArray.id);
                else {
                    const userAddedButRemovedIndex = this.usersListToAdd.findIndex((el) => el.userId == userObject.id);
                    if (userAddedButRemovedIndex !== -1) this.usersListToAdd.splice(userAddedButRemovedIndex, 1);
                }
            }
            if (this.usersList.length == 0) {
                this.form.value.sendToAll = true;
                this.form.controls['sendToAll'].enable();
            }
        } else {
            if (indexArray !== -1) {
                this.usersList.splice(indexArray, 1);
            }
            if (this.usersList.length == 0) {
                this.form.value.sendToAll = true;
                this.form.controls['sendToAll'].enable();
            }
        }
    }

    isSaveDisabled() {
        return (
            !this.form.valid ||
            (this.usersList.length == 0 && this.form.value.sendToAll !== true && this.usersListToAdd.length == 0) ||
            (!this.form.dirty && this.usersListToAdd.length == 0 && this.usersListToDelete.length == 0)
        );
    }

    isCancelDisabled() {
        return !this.isNew && !this.form.dirty && this.usersListToAdd.length == 0 && this.usersListToDelete.length == 0;
    }

    delete() {
        abp.message.confirm(this.l('ScheduledNotificationDeleteWarningMessage'), undefined, (result: boolean) => {
            if (result) {
                const sub = this.notificationService
                    .delete(this.notification.id)
                    .pipe(finalize(() => {}))
                    .subscribe(() => {
                        this.stopLoading();
                        this.notify.info(this.l('DeletedSuccessfully'));
                        this.updateList.emit();
                    });
                this.subscription.add(sub);
            }
        });
    }
}
