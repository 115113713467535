import { Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Subscription } from 'rxjs';
import { finalize } from '@node_modules/rxjs/operators';

import { AppComponentBase } from '@shared/app-component-base';
import { CustomerDto, CustomerServiceProxy } from '@shared/service-proxies/service-proxies';

@Component({
    selector: 'customer-item',
    templateUrl: './customer-item.component.html',
    styles: [],
})
export class CustomerItemComponent extends AppComponentBase implements OnInit, OnDestroy {
    @Input() customer: CustomerDto;
    @Input() withEntityTypeIcon = false;
    @Input() withEntityTypeBadge = false;
    @Input() withAddress = true;
    @Input() withDetailAction = true;
    @Input() withEditAction = true;
    @Input() withDeleteAction = true;
    @Input() withLinkToDetail = false;

    @Output() itemChanged: EventEmitter<void> = new EventEmitter<void>();

    private subscription = new Subscription();

    constructor(
        private injector: Injector,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private customerService: CustomerServiceProxy
    ) {
        super(injector);
    }

    ngOnInit(): void {}

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    seeCustomerDetail() {
        this.router.navigate(['app/customer', this.customer.id, 'detail'], { relativeTo: this.activatedRoute.root });
    }

    editCustomer() {
        this.router.navigate(['app/customer', this.customer.id, 'edit'], { relativeTo: this.activatedRoute.root });
    }

    deleteCustomer() {
        return abp.message.confirm(this.l('CustomerDeleteWarningMessage', this.customer.businessName), '', (result: boolean) => {
            if (result) {
                const sub = this.customerService
                    .delete(this.customer.id)
                    .pipe(finalize(() => {}))
                    .subscribe(() => {
                        this.notify.info(this.l('DeletedSuccessfully'));
                        this.itemChanged.emit();
                    });

                this.subscription.add(sub);
            }
        });
    }
}
