<div *ngIf="service" class="d-flex align-items-center justify-content-between">
    <span>
        <em class="fas fa-align-left mr-2" *ngIf="withEntityTypeIcon"></em>

        <span class="badge badge-dark mr-2" *ngIf="withEntityTypeBadge">{{ service.entityType | localize }}</span>

        <span>{{ service.description }}</span>
    </span>

    <span class="d-flex align-items-center justify-content-end">
        <!--START CONTRACT INFO-->
        <ng-container *ngIf="service.contract && withContract && isEnabledFeatureContracts">
            <span class="small text-left d-inline-block mx-4">
                <span class="text-uppercase text-muted">{{ 'Contract' | localize }}<br /></span>
                <contract-basic-item class="d-inline" [contract]="service.contract"></contract-basic-item>
            </span>
        </ng-container>
        <!--END CONTRACT INFO-->

        <!--START CUSTOMER INFO-->
        <ng-container *ngIf="service?.contract?.customer && withCustomer && isEnabledFeatureCustomers">
            <span class="small text-left d-inline-block mx-4">
                <span class="text-uppercase text-muted">{{ 'Customer' | localize }}<br /></span>
                <customer-basic-item class="d-inline" [customer]="service?.contract?.customer"></customer-basic-item>
            </span>
        </ng-container>
        <!--END CUSTOMER INFO-->

        <!--START SERVICE PERIOD-->
        <span class="small text-left d-inline-block mx-4" *ngIf="service.startDate">
            <span class="text-uppercase text-muted">{{ 'Period' | localize }}</span><br/>
            <span class="text-uppercase text-muted">{{ 'From' | localize }}</span>
            {{ service.startDate.toDate() | date: 'dd/MM/yyyy' }}<br />
            <span *ngIf="service.endDate">
                <span class="text-uppercase text-muted">{{ 'To' | localize }}</span>
                {{ service.endDate.toDate() | date: 'dd/MM/yyyy' }}
            </span>
        </span>
        <!--END SERVICE PERIOD-->

        <!--START SERVICE FUND AMOUNT-->
        <span class="small text-left d-inline-block mx-4" *ngIf="service.fundAmount">
            <span class="text-uppercase text-muted">{{ 'FundAmount' | localize }}<br /></span>
            {{ service.fundAmount | currency: 'EUR' }}
        </span>
        <!--END SERVICE FUND AMOUNT-->

        <!--START SERVICE ACTIONS-->
        <span class="mx-1" *ngIf="withActions">
            <a type="button" class="btn btn-default btn-sm mr-1" [routerLink]="['/app/service', service.id, 'detail']" [title]="'Details' | localize">
                <em class="fas fa-eye"></em>
                <span class="sr-only"> {{ 'Details' | localize }} </span>
            </a>

            <a type="button" class="btn btn-default btn-sm mr-1" [routerLink]="['/app/service', service.id, 'edit']" *ngIf="isGranted(permissionsConst.Service_Edit)" [title]="'Edit' | localize">
                <em class="fas fa-pencil-alt text-success"></em>
                <span class="sr-only"> {{ 'Edit' | localize }} </span>
            </a>

            <button type="button" class="btn btn-default btn-sm" *ngIf="isGranted(permissionsConst.Service_Delete)" (click)="delete(service)" [title]="'Delete' | localize">
                <em class="fas fa-trash-alt text-danger"></em>
                <span class="sr-only"> {{ 'Delete' | localize }} </span>
            </button>
        </span>
        <!--END SERVICE ACTIONS-->
    </span>
</div>
