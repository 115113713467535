import { Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponentBase } from '@shared/app-component-base';
import { OrganizationUnitDto, OrganizationUnitServiceProxy } from '@shared/service-proxies/service-proxies';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'organization-unit-item',
    templateUrl: './organization-unit-item.component.html',
    styleUrls: ['./organization-unit-item.component.scss'],
})
export class OrganizationUnitItemComponent extends AppComponentBase implements OnInit, OnDestroy {
    @Input() organizationUnit: OrganizationUnitDto;
    @Input() withDetail: boolean = true;
    @Input() withEdit: boolean = true;
    @Input() withDelete: boolean = true;
    @Output() itemChanged: EventEmitter<void> = new EventEmitter<void>();

    private subscription = new Subscription();

    constructor(private injector: Injector, private router: Router, private activatedRoute: ActivatedRoute, private organizationUnitService: OrganizationUnitServiceProxy) {
        super(injector);
    }

    ngOnInit(): void {}

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    seeOrganizationUnitDetail(organizationUnit) {
        this.router.navigate(['app/organizationUnit', organizationUnit.id, 'detail'], { relativeTo: this.activatedRoute.root });
    }

    editOrganizationUnit(organizationUnit) {
        this.router.navigate(['app/organizationUnit', organizationUnit.id, 'edit'], { relativeTo: this.activatedRoute.root });
    }

    deleteOrganizationUnit(organizationUnit) {
        abp.message.confirm(this.l('OrganizationUnitDeleteWarningMessage', this.organizationUnit.displayName), '', (result: boolean) => {
            if (result) {
                const sub = this.organizationUnitService
                    .delete(this.organizationUnit.id)
                    .pipe(finalize(() => {}))
                    .subscribe(() => {
                        this.notify.info(this.l('DeletedSuccessfully'));
                        this.itemChanged.emit();
                    });

                this.subscription.add(sub);
            }
        });
    }
}
