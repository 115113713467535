<div *ngFor="let associatedEntity of associatedEntities; let i = index">
    <div class="d-flex align-items-start justify-content-between" *ngIf="isUser(associatedEntity) && associatedEntity.user">
        <user-item class="w-100" [withEntityTypeIcon]="true" [withEntityTypeBadge]="true" [user]="associatedEntity.user"></user-item>

        <button
            type="button"
            class="btn btn-default btn-sm m-0"
            *ngIf="isGranted(permissionsConst.File_Associate)"
            (click)="removeAssociation(associatedEntity, i)"
            [title]="'RemoveAssociation' | localize"
        >
            <em class="fas fa-times text-danger"></em>
            <span class="sr-only"> {{ 'RemoveAssociation' | localize }} </span>
        </button>
    </div>

    <div class="d-flex align-items-start justify-content-between" *ngIf="isService(associatedEntity) && associatedEntity.service">
        <div class="d-flex align-items-center justify-content-between w-100">
            <service-item class="w-100" [withEntityTypeIcon]="true" [withEntityTypeBadge]="true" [service]="associatedEntity.service"></service-item>

            <button
                type="button"
                class="btn btn-default btn-sm mx-0"
                *ngIf="isGranted(permissionsConst.File_Associate)"
                (click)="removeAssociation(associatedEntity, i)"
                [title]="'RemoveAssociation' | localize"
            >
                <em class="fas fa-times text-danger"></em>
                <span class="sr-only"> {{ 'RemoveAssociation' | localize }} </span>
            </button>
        </div>
    </div>

    <div class="d-flex align-items-start justify-content-between" *ngIf="isContract(associatedEntity) && associatedEntity.contract">
        <contract-item class="w-100" [withEntityTypeIcon]="true" [withEntityTypeBadge]="true" [contract]="associatedEntity.contract"></contract-item>

        <button
            type="button"
            class="btn btn-default btn-sm my-1"
            *ngIf="isGranted(permissionsConst.File_Associate)"
            (click)="removeAssociation(associatedEntity, i)"
            [title]="'RemoveAssociation' | localize"
        >
            <em class="fas fa-times text-danger"></em>
            <span class="sr-only"> {{ 'RemoveAssociation' | localize }} </span>
        </button>
    </div>

    <hr *ngIf="i < associatedEntities.length - 1" />
</div>
