import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponentBase } from '@shared/app-component-base';
import { UserDto, UserServiceProxy } from '@shared/service-proxies/service-proxies';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { Observable, Observer, of, Subscription } from 'rxjs';
import { finalize, ignoreElements, map, switchMap } from 'rxjs/operators';

@Component({
    selector: 'user-autocomplete',
    templateUrl: './user-autocomplete.component.html',
    styles: [],
})
export class UserAutocompleteComponent extends AppComponentBase implements OnInit {
    @Input() newUser: UserDto;
    @Input() alreadyUsedUsers: UserDto[];
    @Input() isOperator: boolean;
    @Input() isCoordinator: boolean;
    @Input() searchBetweenAllUsers: boolean;
    @Input() inputSize: 'sm' | 'lg';
    @Output() addNewUser = new EventEmitter();

    isSearchingUser: boolean = false;

    usersObs$: Observable<any[]>;
    selectedUser: string;

    constructor(private injector: Injector, public userService: UserServiceProxy, private router: Router, private activatedRoute: ActivatedRoute) {
        super(injector);
    }

    /**
     * TYPEAHEAD CODE
     * send a rest call when the user reach 2 character length,
     * to obtain user filtered list
     * @param event
     */
    getUsersOnInput(event) {
        const asyncSelected = event.target.value;
        this.usersObs$ = new Observable((observer: Observer<string>) => {
            observer.next(asyncSelected);
        }).pipe(
            switchMap((query) => {
                this.isSearchingUser = false;
                if (query) {
                    this.isSearchingUser = true;
                    if (this.isOperator) {
                        return this.userService.getUsersAssignableInServiceOperatorByKeyword(query).pipe(
                            map((data) => (data && data) || []),
                            finalize(() => (this.isSearchingUser = false))
                        );
                    } else if (this.isCoordinator) {
                            return this.userService.getUsersAssignableInServiceOperatorByKeyword(query).pipe(
                                map((data) => (data && data) || []),
                                finalize(() => (this.isSearchingUser = false))
                            );
                    } else if (this.searchBetweenAllUsers) {
                        return this.userService.getUsersByKeyword(query).pipe(
                            map((data) => (data && data) || []),
                            finalize(() => (this.isSearchingUser = false))
                        );
                    } else {
                            return this.userService.getUsersAssignableInServiceBeneficiaryByKeyword(query).pipe(
                                map((data) => (data && data) || []),
                                finalize(() => (this.isSearchingUser = false))
                            );
                    }
                }
                return of([]);
            })
        );
    }

    typeAheadOnSelect(e: TypeaheadMatch): void {
        const userObj = this.alreadyUsedUsers.find((el) => {
            if (el.id == e.item.id) return el;
        });
        if (!userObj) {
            this.addNewUser.emit(e.item);
            this.selectedUser = e.item.fullName;
        } else {
            this.selectedUser = '';
        }
    }

    ngOnInit(): void {}

    ngOnChange() {
        if (!this.newUser) this.selectedUser = null;
    }
}
