<div class="d-inline-flex align-items-center justify-content-between border border-radius-default shadow-default p-2 mb-2">
    <span>
        <em class="fas fa-user mr-2"></em>
        <span> {{ user.fullName }} </span>
    </span>

    <button type="button" class="btn btn-default btn-sm ml-3" (click)="delete()" [disabled]="isCancelButtonBlocked">
        <em class="fas fa-times"></em>
        <span class="sr-only">{{ 'Delete' | localize }}</span>
    </button>
</div>
