import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContractDto, ContractPartnerDto, CustomerDto } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/app-component-base';
import { FeatureConsts } from '@shared/FeatureConsts';

@Component({
    selector: 'contract-item',
    templateUrl: './contract-item.component.html',
    styles: [],
})
export class ContractItemComponent extends AppComponentBase implements OnInit {
    @Input() contract: ContractDto;
    @Input() withEntityTypeIcon = false;
    @Input() withEntityTypeBadge = false;
    @Input() customerVisible = this.feature.isEnabled(FeatureConsts.Customers);

    @Output()
    deleteContract = new EventEmitter<ContractDto>();

    constructor(private injector: Injector, private router: Router, private activatedRoute: ActivatedRoute) {
        super(injector);
    }

    ngOnInit(): void {}

    seeContractDetail(contract) {
        this.router.navigate(['app/contract', contract.id, 'detail'], { relativeTo: this.activatedRoute.root });
    }

    editContract(contract) {
        this.router.navigate(['app/contract', contract.id, 'edit'], { relativeTo: this.activatedRoute.root });
    }

    get leaderCustomer(): CustomerDto {
        return this.contract?.partners?.find((partner: ContractPartnerDto) => partner?.isLeader && partner?.customer)?.customer;
    }
}
