import { Component, ContentChild, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { isArray } from 'lodash-es';
import { FileParameter } from '@shared/service-proxies/service-proxies';

@Component({
    selector: 'upload-button',
    templateUrl: './upload-button.component.html',
    styleUrls: ['./upload-button.component.scss'],
})
export class UploadButtonComponent implements OnInit, OnChanges {
    @ContentChild('content')
    content: ElementRef;

    @Input()
    types: string[] | string;

    @Input()
    disabled = false;

    @Input()
    multiple = false;

    @Input()
    classes:
        | string
        | string[]
        | Set<string>
        | {
              [klass: string]: any;
          };

    @ViewChild('inputFile')
    inputFile: any;

    @Output('files')
    filesEmitter = new EventEmitter<FileParameter[]>();

    files: FileParameter[] = [];

    accept = '*';

    constructor() {}

    ngOnInit(): void {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.types) {
            if (isArray(this.types)) {
                this.accept = this.types.join(',');
            } else {
                this.accept = this.types;
            }
        }
    }

    onChangeFile(event: Event): void {
        this.files = [];
        const target = event.target as HTMLInputElement;
        const files: FileList = target.files;
        void new Promise<void>((resolve) => {
            for (let i = 0; i < files.length; i++) {
                const file: File = files.item(i);
                const reader: FileReader = new FileReader();
                reader.onloadend = (e: ProgressEvent<FileReader>) => {
                    const data: Blob = new Blob([e.target.result], { type: file.type });
                    const fileParameter: FileParameter = {
                        data,
                        fileName: file.name,
                    };
                    this.files.push(fileParameter);
                    if (!this.multiple || i === files.length - 1) {
                        resolve();
                    }
                };
                reader.readAsArrayBuffer(file);
            }
        })
            .catch(() => this.filesEmitter.emit([]))
            .then(() => {
                this.filesEmitter.emit(this.files);
                this.inputFile.nativeElement.value = '';
            });
    }
}
