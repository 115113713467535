export class HrRequestTypeStaticCodeConst {
    static Vacation = 'Vacation';
    static WorkPermit = 'WorkPermit';
    static WorkingHoursRecovery = 'WorkingHoursRecovery';
    static Overtime = 'Overtime';
    static Illness = 'Illness';
    static ParentalLeave = 'ParentalLeave';
    static Law104 = 'Law104';
    static MarriageLeave = 'MarriageLeave';
    static ChildrenIllness = 'ChildrenIllness';
    static StudyPermit = 'StudyPermit';
    static BereavementPermit = 'BereavementPermit';
    static BloodDonationPermit = 'BloodDonationPermit';
    static UnpaidPermit = 'UnpaidPermit';
    static ExternalService = 'ExternalService';
    static WorkTrip = 'WorkTrip';
    static Supplying = 'Supplying';
}

export class HrRequestStateConst {
    static ReadyForEvaluation = 'ReadyForEvaluation';
    static InEvaluation = 'InEvaluation';
    static Approved = 'Approved';
    static Rejected = 'Rejected';
    static Draft = 'Draft';
    static ApprovedCancel = 'ApprovedCancel';
    static RejectedCancel = 'RejectedCancel';
    static ReadyForCancelEvaluation = "ReadyForCancelEvaluation";
    static InCancelEvaluation = "InCancelEvaluation";
}

export class HRRequestCategoryApprovalFlowConst {
    static Approvation = 'Approvation';
    static Cancellation = 'Cancellation';
}
