import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerDto } from '@shared/service-proxies/service-proxies';

@Component({
    selector: 'customer-basic-item',
    templateUrl: './customer-basic-item.component.html',
    styles: [],
})
export class CustomerBasicItemComponent implements OnInit {
    @Input() customer: CustomerDto;
    @Input() withRemoveAction = true;
    @Input() withShadow = false;
    @Output() getItemToRemove: EventEmitter<CustomerDto> = new EventEmitter<CustomerDto>();

    constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

    ngOnInit(): void {}

    goToCustomerDetail() {
        this.router.navigate(['app/customer', this.customer?.id, 'detail'], { relativeTo: this.activatedRoute.root });
    }

    delete() {
        this.getItemToRemove.emit(this.customer);
    }
}
