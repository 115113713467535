<div
    *ngIf="customer"
    class="d-flex align-items-center justify-content-between mb-2 mr-2"
    [ngClass]="{ 'p-2 bg-white shadow-default border-radius-default': withShadow }"
    [title]="'GoToDetail' | localize"
    (click)="goToCustomerDetail()"
>
    <a class="btn btn-link p-0 m-0 mr-2">
        <em class="fas fa-link text-primary mr-2 fa-xs"></em>
        <span>{{ customer?.businessName }}</span>
    </a>

    <div>
        <button type="button" class="btn btn-default btn-sm" *ngIf="withRemoveAction" [title]="'Remove' | localize" (click)="delete()">
            <em class="fas fa-trash"></em>
            <span class="sr-only">{{ 'Remove' | localize }}</span>
        </button>
    </div>
</div>
