<section class="mb-3">
    <div class="card">
        <div class="card-header">
            <div class="col-lg-12">
                <div class="d-flex flex-row align-items-center justify-content-between mb-2">
                    <h4 class="m-0">{{ 'HRRequestObjectsToBuy' | localize }}</h4>

                    <button type="button"
                            [title]="'Add' | localize"
                            (click)="addNewSupplyingObject(null)"
                            [disabled]="isNotDraft || hrRequestSupplyingObjectFormArray.disabled"
                            class="btn btn-primary btn-sm mt-2">
                        <em class="fas fa-plus mr-2"></em>
                        {{ 'Add' | localize }}
                    </button>
                </div>
            </div>
        </div>

        <div class="card-body table-responsive overflow-inherit" [busy]="isTableLoading">
            <div>
                <ng-container *ngFor="
                        let hRRequestSupplyingObjectFormGroup of hrRequestSupplyingObjectFormArray.controls
                            | paginate
                                : {
                                      id: 'hrRequestSupplyingObjectListPaged',
                                      itemsPerPage: pageSize,
                                      currentPage: pageNumber,
                                      totalItems: totalItems
                                  };
                        let i = index">
                    <hrrequest-detail-supplying-object-item
                                                           [formGroup]="hRRequestSupplyingObjectFormGroup"
                                                           (cancelAddItemEvent)="cancelAddItem(i)"
                                                           [hrRequestSupplyingObjectList]="hrRequestSupplyingObjectList"
                                                           [hRRequest] ="hRRequest"
                                                           (refreshList)="displayChanges()"
                                                           (updateSupplyingObjectList)="updateHRRequest()"></hrrequest-detail-supplying-object-item>
                    <hr *ngIf="i < hrRequestSupplyingObjectFormArray.controls.length - 1" />
                </ng-container>

                <div *ngIf="hrRequestSupplyingObjectFormArray.controls.length == 0" class="text-center">
                    <span>{{ 'HRRequestNoSupplyingObjectFound' | localize }}</span>
                </div>
            </div>
        </div>

        <div class="card-footer bg-light border-top">
            <div class="row">
                <div class="col-sm-4 col-12 text-sm-left text-center">
                    <button class="btn btn-default btn-sm" (click)="refresh()">
                        <em class="fas fa-sync mr-2"></em>
                        {{ 'Refresh' | localize }}
                    </button>
                </div>
                
                <div class="col-sm-4 col-12 text-center">
                    <p class="mb-0 my-2">
                        {{ 'TotalRecordsCount' | localize: totalItems }}
                    </p>
                </div>
                <div class="col-sm-4 col-12">
                    <div class="float-sm-right m-auto">
                        <abp-pagination-controls id="hrRequestSupplyingObjectListPaged" (pageChange)="getDataPage($event)"></abp-pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
