<div *ngIf="customer" class="d-flex align-items-center justify-content-between">
    <span>
        <em class="fas fa-address-book mr-2" *ngIf="withEntityTypeIcon"></em>

        <span class="badge badge-dark mr-2" *ngIf="withEntityTypeBadge">{{ customer.entityType | localize }}</span>

        <ng-container *ngIf="withLinkToDetail; else span">
            <a role="button" class="btn-link" (click)="seeCustomerDetail()">
                <em class="fas fa-external-link-alt mr-2"></em>
                <span>{{ customer.businessName }}</span>
            </a>
        </ng-container>
        <ng-template #span>
            <span>{{ customer.businessName }}</span>
        </ng-template>
    </span>

    <span class="d-flex align-items-center justify-content-end">
        <!--START CUSTOMER INFO-->
        <span class="small text-right d-inline-block mx-4" *ngIf="customer.address && withAddress">
            <span class="text-uppercase text-muted">{{ 'Address' | localize }}<br /></span>
            <address-shared [addressDto]="customer.address"></address-shared>
        </span>

        <span class="small text-left d-inline-block mx-4" *ngIf="customer.vatCode">
            <span class="text-uppercase text-muted">{{ 'VatCode' | localize }}<br /></span>
            {{ customer.vatCode }}
        </span>

        <span class="small text-left d-inline-block mx-4" *ngIf="customer.fiscalCode">
            <span class="text-uppercase text-muted">{{ 'FiscalCode' | localize }}<br /></span>
            {{ customer.fiscalCode }}
        </span>
        <!--END CUSTOMER INFO-->

        <!--START CUSTOMER ACTIONS-->
        <span class="mx-1">
            <button type="button" class="btn btn-default btn-sm mx-1" *ngIf="withDetailAction" (click)="seeCustomerDetail()" [title]="'Details' | localize">
                <em class="fas fa-eye"></em>
                <span class="sr-only"> {{ 'Details' | localize }} </span>
            </button>

            <button
                type="button"
                class="btn btn-default btn-sm mx-1"
                *ngIf="isGranted(permissionsConst.Customer_Edit) && withEditAction"
                (click)="editCustomer()"
                [title]="'Edit' | localize"
            >
                <em class="fas fa-pencil-alt text-success"></em>
                <span class="sr-only"> {{ 'Edit' | localize }} </span>
            </button>

            <button
                type="button"
                class="btn btn-default btn-sm mx-1"
                *ngIf="isGranted(permissionsConst.Customer_Delete) && withDeleteAction"
                (click)="deleteCustomer()"
                [title]="'Delete' | localize"
            >
                <em class="fas fa-trash-alt text-danger"></em>
                <span class="sr-only"> {{ 'Delete' | localize }} </span>
            </button>
        </span>
        <!--END CUSTOMER ACTIONS-->
    </span>
</div>
