import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizationUnitDto } from '@shared/service-proxies/service-proxies';

@Component({
    selector: 'organization-unit-basic-item',
    templateUrl: './organization-unit-basic-item.component.html',
    styles: [],
})
export class OrganizationUnitBasicItemComponent implements OnInit {
    @Input() organizationUnit: OrganizationUnitDto;

    constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

    ngOnInit(): void {}

    goOnOrganizationUnitDetail() {
        this.router.navigate(['app', 'organizationUnit', this.organizationUnit.id, 'detail'], { relativeTo: this.activatedRoute.root });
    }
}
