import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';

import {Observable, Observer} from 'rxjs';
import {finalize, map, switchMap} from 'rxjs/operators';
import {TypeaheadMatch} from 'ngx-bootstrap/typeahead';

import {WorkContractServiceProxy, WorkContractTypeDto} from '@shared/service-proxies/service-proxies';

@Component({
    selector: 'work-contract-type',
    templateUrl: './work-contract-type.component.html',
})
export class WorkContractTypeComponent implements OnInit {
    //Accetta in input il formGroup del contract type
    @Input() formGroup: FormGroup;
    selectedWorkContractType: string;
    workContractTypeObs$: Observable<any>;

    isSearchingContractType: boolean = false;

    get controls() {
        return this.formGroup.controls;
    }

    constructor(private workContractTypeService: WorkContractServiceProxy) {}

    ngOnInit(): void {}

    /**
     * TYPEAHEAD CODE
     * send a rest call when the work contract type reach 2 character length,
     * to obtain work contract type filtered list
     * @param event
     */
    getContractTypesOnInput(event) {
        const asyncSelected = event.target.value;
        this.workContractTypeObs$ = new Observable((observer: Observer<string>) => {
            this.formGroup.get('id').setValue(null);
            observer.next(asyncSelected);
        }).pipe(
            switchMap((query) => {
                this.isSearchingContractType = true;
                return this.workContractTypeService.getAllByKeyword(query, undefined).pipe(
                    map((data: WorkContractTypeDto[]) => (data && data) || []),
                    finalize(() => (this.isSearchingContractType = false))
                );
            })
        );
    }

    typeAheadOnSelect(e: TypeaheadMatch): void {
        this.formGroup.patchValue(e.item);
    }
}
