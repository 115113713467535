import { Component, EventEmitter, Injector, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { CreatedHRRequestAndSupplyingObjectDto, HRRequestDto, HRRequestServiceProxy, HRRequestSupplyingObjectDto } from "@shared/service-proxies/service-proxies";
import { AppComponentBase } from '@shared/app-component-base';
import { finalize } from 'rxjs/operators';
import { HrRequestStateConst } from '@hrrequests/hrrequest-const.model';

@Component({
    selector: 'hrrequest-detail-supplying-object-item',
    templateUrl: './hrrequest-detail-supplying-object-item.component.html',
})
export class HrrequestDetailSupplyingObjectItemComponent extends AppComponentBase implements OnInit, OnChanges, OnDestroy {
    @Input() formGroup: FormGroup;
    @Input() hrRequestSupplyingObjectList: HRRequestSupplyingObjectDto[];
    @Input() hRRequest: HRRequestDto;

    @Output() refreshList = new EventEmitter();
    @Output() cancelAddItemEvent = new EventEmitter();
    @Output() updateSupplyingObjectList: EventEmitter<void> = new EventEmitter<void>();
    currencyString: string;
    originalFormValue;
    private subscription: Subscription = new Subscription();
    private decimalValueKeys = ['unitPrice', 'totalPrice'];

    constructor(private injector: Injector, private hrRequestService: HRRequestServiceProxy) {
        super(injector);
    }

    get controls() {
        return this.formGroup.controls;
    }

    get hrRequestStateList() {
        return HrRequestStateConst;
    }

    get hrrequestState() {
        return this.formGroup.controls.hrRequestState.value !== this.hrRequestStateList.Draft;
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.originalFormValue = this.formGroup.value;
    }

    ngOnInit(): void {
        this.currencyString = this.formGroup.controls.currencyType.value;
    }

    cancelActions() {
        if (this.formGroup.get('id').value != '0') {
            this.formGroup.patchValue(this.originalFormValue);
            this.formGroup.markAsPristine();
        } else {
            this.cancelAddItemEvent.emit();
        }
    }

    deleteSupplyingObject() {
        abp.message.confirm(this.l('HRRequestSupplyingObjectDeleteWarningMessage'), '', (result: boolean) => {
            if (result) {
                this.startLoading();
                const sub = this.hrRequestService
                    .deleteSupplyingObject(this.formGroup.controls.hrRequestId.value, this.formGroup.controls.id.value)
                    .pipe(
                        finalize(() => {
                            this.stopLoading();
                        })
                    )
                    .subscribe(() => {
                        this.notify.info(this.l('DeletedSuccessfully'));
                        this.updateSupplyingObjectList.emit();
                    });
                this.subscription.add(sub);
            }
        });
    }

    save() {
        this.startLoading();
        let obsCall: Observable<any>;
        if (this.formGroup.get('id').value == '0') {
            const createHrRequestSupplyingObject: CreatedHRRequestAndSupplyingObjectDto = this.parseDecimalsAsNativeValues(this.decimalValueKeys, this.formGroup.value);
            createHrRequestSupplyingObject.hrRequestId = this.formGroup.controls.hrRequestId.value;
            obsCall = this.hrRequestService.createSupplyingObject(createHrRequestSupplyingObject);
        }
        const sub = obsCall
            .pipe(
                finalize(() => {
                    this.stopLoading();
                })
            )
            .subscribe((res) => {
                this.notify.success(this.l('SavedSuccessfully'));
                this.updateSupplyingObjectList.emit();
            });
        this.subscription.add(sub);
    }
}
