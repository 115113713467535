import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'object-basic-item',
    templateUrl: './object-basic-item.component.html',
    styles: [],
})
export class ObjectBasicItemComponent implements OnInit {
    @Input() objectName: string;
    @Input() objectId: number;
    @Input() objectTypeName: string;

    constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

    ngOnInit(): void {
    }

    goOnObjectDetail() {
        if(this.objectId && this.objectTypeName) this.router.navigate(['app', this.objectTypeName, this.objectId, 'detail'], { relativeTo: this.activatedRoute.root });
    }
}
