<ng-container *ngIf="isEditing; else informationTemplate">
    <div *ngIf="!showAddressForm" class="mb-2">
        <button type="button" class="btn btn-default btn-sm" (click)="openWindowAddress()">
            <em class="fa fa-plus mr-2"></em>
            <span>{{ 'AddAddress' | localize }}</span>
        </button>
        <h6 style="display: inline-block"></h6>
    </div>

    <div *ngIf="showAddressForm" class="container border-left border-right px-2">
        <button type="button" class="btn btn-default btn-sm mb-2" (click)="delete()">
            <em class="fa fa-minus mr-2"></em>
            <span>{{ 'RemoveAddress' | localize }}</span>
        </button>

        <form class="form-horizontal mb-2" [formGroup]="controls.address">
            <input type="hidden" formControlName="id" />
            <div class="form-group row">
                <label class="col-md-3 col-form-label" for="addressName">
                    {{ 'AddressName' | localize }}
                </label>

                <div class="col-md-9 mb-2">
                    <input class="form-control" type="text" name="addressName" id="addressName" formControlName="name" #addressNameEl />
                    <abp-validation-summary [control]="controls.address.get('name')" [controlEl]="addressNameEl"></abp-validation-summary>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-3 col-form-label" for="description">
                    {{ 'AddressDescription' | localize }}
                </label>

                <div class="col-md-9 mb-2">
                    <input class="form-control" type="text" name="description" id="description" formControlName="description" #descriptionEl />
                    <abp-validation-summary [control]="controls.address.get('description')" [controlEl]="descriptionEl"></abp-validation-summary>
                </div>
            </div>

            <!-- MUNICIPALITY START -->
            <div class="form-group row">
                <label class="col-md-3 col-form-label" for="municipality">
                    {{ 'Municipality' | localize }}
                </label>
                <div class="col-md-9 mb-2" [formGroup]="controls.address.get('municipality')">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text" [tooltip]="'MunicipalityTypeaheadHelper' | localize">
                                <em class="fas fa-info"></em>
                            </span>
                        </div>

                        <input type="hidden" formControlName="id" />
                        <input
                            type="text"
                            class="form-control"
                            id="municipality"
                            [placeholder]="'SearchWithThreeDot' | localize"
                            (input)="getMunicipalitiesOnInput($event)"
                            [typeaheadItemTemplate]="municipalitiesTemplate"
                            (typeaheadOnSelect)="typeAheadOnSelect($event)"
                            [typeahead]="municipalitiesObs$"
                            [typeaheadAsync]="true"
                            [isAnimated]="true"
                            formControlName="name"
                            typeaheadOptionField="municipality.name"
                            autocomplete="off"
                            #indirizziComuneEl
                        />
                        <ng-template #municipalitiesTemplate let-municipality="item" class="dropdown-municipalities" let-index="index">
                            {{ municipality.name.toString() | titlecase }}
                        </ng-template>
                    </div>
                    <abp-validation-summary [control]="controls.address.get('municipality').get('id')" [controlEl]="indirizziComuneEl"></abp-validation-summary>
                </div>
            </div>
            <!-- MUNICIPALITY END -->

            <div class="form-group row">
                <label class="col-md-3 col-form-label" for="postalCode">
                    {{ 'PostalCode' | localize }}
                </label>
                <div class="col-md-9 mb-2">
                    <input class="form-control" type="text" name="postalCode" id="postalCode" formControlName="postalCode" #postalCodeEl />
                    <abp-validation-summary [control]="controls.address.get('postalCode')" [controlEl]="postalCodeEl"></abp-validation-summary>
                </div>
            </div>
        </form>
    </div>
</ng-container>

<ng-template #informationTemplate>
    <div *ngIf="addressDto != null">{{ addressDto.name }}, {{ addressDto.description }} {{ addressDto.postalCode }} {{ addressDto.municipality.name }}</div>
</ng-template>
