import { ElementRef, Injector, Renderer2 } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { AppConsts } from '@shared/AppConsts';
import { AbpMultiTenancyService, FeatureCheckerService, LocalizationService, MessageService, NotifyService, PermissionCheckerService, SettingService } from 'abp-ng2-module';

import { AppSessionService } from '@shared/session/app-session.service';
import { PermissionConsts } from './PermissionConsts';
import { RoleConst } from './RoleConst';
import { UserDto } from './service-proxies/service-proxies';
import { Location, Time } from '@angular/common';
import { FeatureConsts } from './FeatureConsts';
import { SettingConsts } from './SettingConsts';
import { environment } from '@environments/environment';
import { Title } from '@angular/platform-browser';
import { LocalizationConsts } from './LocalizationConsts';
import { isNaN } from 'lodash-es';

export abstract class AppComponentBase {
    localizationSourceName = AppConsts.localization.defaultLocalizationSourceName;

    localization: LocalizationService;
    permission: PermissionCheckerService;
    feature: FeatureCheckerService;
    notify: NotifyService;
    setting: SettingService;
    message: MessageService;
    multiTenancy: AbpMultiTenancyService;
    appSession: AppSessionService;
    elementRef: ElementRef;
    titlePage: Title;
    loggedUserId = abp.session.userId;
    datePickerThemeClass = environment.datePickerThemeClass;
    sampleFormattedNumber: Intl.NumberFormatPart[];

    get permissionsConst() {
        return PermissionConsts;
    }

    get roleConst() {
        return RoleConst;
    }

    get featureConst() {
        return FeatureConsts;
    }

    get settingConst() {
        return SettingConsts;
    }

    renderer: Renderer2;
    location: Location;

    formatter = new Intl.NumberFormat(abp.localization.currentLanguage.name + '-' + abp.localization.currentLanguage.name.toUpperCase(), {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    constructor(injector: Injector) {
        this.localization = injector.get(LocalizationService);
        this.permission = injector.get(PermissionCheckerService);
        this.feature = injector.get(FeatureCheckerService);
        this.notify = injector.get(NotifyService);
        this.setting = injector.get(SettingService);
        this.message = injector.get(MessageService);
        this.multiTenancy = injector.get(AbpMultiTenancyService);
        this.appSession = injector.get(AppSessionService);
        this.elementRef = injector.get(ElementRef);
        this.renderer = injector.get(Renderer2);
        this.location = injector.get(Location);
        this.titlePage = injector.get(Title);
        this.sampleFormattedNumber = this.formatter.formatToParts(123456.7);
    }

    l(key: string, ...args: any[]): string {
        let localizedText = this.localization.localize(key, this.localizationSourceName);

        if (!localizedText) {
            localizedText = key;
        }

        if (!args || !args.length) {
            return localizedText;
        }

        args.unshift(localizedText);
        return abp.utils.formatString.apply(this, args);
    }

    isGranted(permissionName: string): boolean {
        return this.permission.isGranted(permissionName);
    }

    isFeatureEnabled(featureName: string) {
        return this.feature.isEnabled(featureName);
    }

    isInRole(user: UserDto, role: string) {
        if (user.roleNames) {
            return user.roleNames.some((r) => {
                return r === role;
            });
        } else return false;
    }

    startLoading() {}

    stopLoading() {}

    goBack() {
        this.location.back();
    }

    setTitle(t: string) {
        this.titlePage.setTitle(this.l(t));
    }

    /**
     * Funzione ricorsiva che consente di trasformare i numeri decimali espressi con notazione utente (1234,56, typeof 'string') in tipo nativo (1234.56, typeof 'number')
     * @param keys Array di chiavi (string) che vengono ricercate
     * @param value
     * @returns
     */
    parseDecimalsAsNativeValues(keys: string[], value: any): any {
        for (const key in value) {
            if (keys.includes(key) && typeof value[key] === 'string') {
                value[key] = +value[key].replace(/\,/gi, '.');
            } else if (typeof value[key] === 'object') {
                this.parseDecimalsAsNativeValues(keys, value[key]);
            }
        }

        return value;
    }

    formatDecimalsAsUserFormat(keys: string[], value: any): any {
        for (const key in value) {
            if (keys.includes(key) && typeof value[key] === 'number') {
                value[key] = this.replacePointWithComma(key, value);
            } else if (typeof value[key] === 'object') {
                this.formatDecimalsAsUserFormat(keys, value[key]);
            }
        }
        return value;
    }

    /**
     * Check current localization language, then replace point to command
     * @param key
     * @param value
     * @returns
     */
    replacePointWithComma(key: string, value) {
        let replacedValue = '';
        switch (this.localization.currentLanguage.name) {
            case LocalizationConsts.Italian: {
                replacedValue = value[key].toFixed(2).toString().replace(/\./gi, ',');
                break;
            }
        }
        return replacedValue;
    }

    parseDecimalsMinutesAsNativeValues(keys: string[], value: any): any {
        for (const key in value) {
            if (keys.includes(key) && typeof value[key] === 'string') {
                if (value[key]) {
                    value[key] = +value[key].replace(/\,/gi, '.');
                    value[key] = this.timeToMinutes(this.getTime(value[key].toFixed(2).toString()));
                }
            } else if (typeof value[key] === 'object') {
                this.parseDecimalsMinutesAsNativeValues(keys, value[key]);
            }
        }
        return value;
    }

    timeToMinutes(time: Time) {
        return time.hours * 60 + time.minutes;
    }

    getTime(hoursMinutes: string): Time {
        const hours = hoursMinutes.split('.')[0];
        const minutes = hoursMinutes.split('.')[1];
        return { hours: +hours, minutes: +minutes };
    }

    formatMinutesAsUserFormat(keys: string[], value: any): any {
        for (const key in value) {
            if (keys.includes(key) && typeof value[key] === 'number') {
                value[key] = this.minutesToTime(value[key]);
            } else if (typeof value[key] === 'object') {
                this.formatMinutesAsUserFormat(keys, value[key]);
            }
        }
        return value;
    }

    minutesToTime(totalMinutes: number): string {
        const tempMinutes = totalMinutes % 60;
        const minutes = tempMinutes.toString().length > 1 ? tempMinutes : '0' + tempMinutes;
        const hours = (totalMinutes - +minutes) / 60;
        const commaDot = this.localization.currentLanguage.name == LocalizationConsts.Italian ? ',' : '.';
        return hours + commaDot + minutes;
    }

    formatFundAmountAsNativeValue(value: any): number {
        let stringifiedValue = value.toString();
        let nativeValue;
        if (stringifiedValue) {
            const escapedDecimalDivider = `\\${this.sampleFormattedNumber.find((el) => el.type == 'decimal').value}`;
            const escapedGroupDivider = `\\${this.sampleFormattedNumber.find((el) => el.type == 'group').value}`;
            var groupDividerRegex = new RegExp(`${escapedGroupDivider}`, 'gi');
            var decimalDividerRegex = new RegExp(`${escapedDecimalDivider}`, 'gi');
            nativeValue = parseFloat(stringifiedValue.replace(groupDividerRegex, '').replace(decimalDividerRegex, '.'));
        }
        return nativeValue;
    }

    formatFundAmountAsUserFormat(form: FormGroup, value): void {
        if (!isNaN(value)) {
            const formattedNumber = this.formatNumberAsUserFormat(value);
            form.get('fundAmount').setValue(formattedNumber, { emitEvent: false });
        }
    }

    formatNumberAsUserFormat(value: number): string {
        if (!isNaN(value)) {
            const formattedNumber = this.formatter.format(value);
            return formattedNumber;
        }
        return value.toString();
    }

    formatFundAmountAsUserFormatRuntime(form: FormGroup, value) {
        let parsedNumber = this.formatFundAmountAsNativeValue(value);
        this.formatFundAmountAsUserFormat(form, parsedNumber);
    }
}
