import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { LocalizationConsts } from '@shared/LocalizationConsts';
import * as moment from 'moment';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@node_modules/@angular/forms';

export class ValidatorsHelper {
    public static emailRegexValidator(c: FormControl) {
        if (c.value != '') {
            return /(?:[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[A-Za-z0-9-]*[A-Za-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
                c.value
            )
                ? null
                : {
                      email: true,
                  };
        } else return null;
    }

    /**
     * Check current language, then validate decimal number with comma or point
     * @param c
     * @returns
     */
    public static twoDecimalRegexValidator(c: FormControl) {
        if (c.value) {
            const value = c.value.toLocaleString();
            let isValid = false;
            switch (abp.localization.currentLanguage.name) {
                case LocalizationConsts.Italian: {
                    isValid = /^[0-9]+([,][0-9]{2})?$/.test(value);
                    break;
                }
                case LocalizationConsts.English: {
                    isValid = /^[0-9]+([.][0-9]{2})?$/.test(value);
                    break;
                }
            }
            return isValid
                ? null
                : {
                      FeePerHourValidator: true,
                  };
        } else return null;
    }

    /**
     * Check current Culture, then validate decimal number with comma or point
     * @param c
     * @returns
     */
    public static decimalRegexValidator(c: FormControl) {
        if (c.value) {
            const value = c.value.toLocaleString();
            if (abp.localization.isCurrentCulture(LocalizationConsts.Italian)) {
                const regexDecimalIt = new RegExp('^[0-9]+([,][0-9]{1,2})?$');
                return regexDecimalIt.test(value)
                    ? null
                    : {
                          FeePerHourItValidator: true,
                      };
            } else if (abp.localization.isCurrentCulture(LocalizationConsts.English)) {
                const regexDecimalEn = new RegExp('^[0-9]+([.][0-9]{1,2})?$');
                return regexDecimalEn.test(value)
                    ? null
                    : {
                          FeePerHourEnValidator: true,
                      };
            } else {
                console.error('decimalRegexValidator error occurred');
            }
        } else return null;
    }

    public static twoDateRangeValidator(c: FormControl) {
        const invalid = c.value.startDate > c.value.endDate;

        return invalid ? { invalidDate: true } : null;
    }

    public static capRegexValidator(c: FormControl) {
        if (c.value != null && c.value != '') {
            return /^[0-9]{5}$/.test(c.value)
                ? null
                : {
                      CapValidator: true,
                  };
        } else return null;
    }

    public static vatRegexValidator(c: FormControl) {
        if (c.value != null && c.value != '') {
            return /^[0-9]{11}$/.test(c.value)
                ? null
                : {
                      VatCodeValidator: true,
                  };
        } else return null;
    }

    /**
     * REGEX TO CHECK FISCAL CODE FOR LEGAL ENTITIES AND PERSONS
     * @param c
     * @returns
     */
    public static fiscalCodeRegexValidator(c: FormControl) {
        if (c.value != null && c.value != '') {
            if (/^([A-Z]{6}[0-9LMNPQRSTUV]{2}[ABCDEHLMPRST]{1}[0-9LMNPQRSTUV]{2}[A-Z]{1}[0-9LMNPQRSTUV]{3}[A-Z]{1})$|^[0-9]{11}$/.test(c.value.toUpperCase())) {
                return null;
            } else {
                return {
                    FiscalCodeValidator: true,
                };
            }
        }
    }

    public static integerNumberRegexValidator(c: FormControl) {
        if (c.value) {
            return /^[0-9]*$/.test(c.value)
                ? null
                : {
                      IntegerValidator: true,
                  };
        } else return null;
    }

    public static hourTimeRangeValidator(c: FormGroup) {
        const formRawValue = c.getRawValue();
        if (formRawValue.intervalStart && formRawValue.intervalEnd && formRawValue.timeStart && formRawValue.timeEnd) {
            const hourStart = c.get('timeStart').value.split(':')[0];
            const minuteStart = c.get('timeStart').value.split(':')[1];
            const intervalSt = moment(c.get('intervalStart').value);
            intervalSt.set({ h: hourStart, m: minuteStart, s: 0 }).toDate();

            const hourEnd = c.get('timeEnd').value.split(':')[0];
            const minuteEnd = c.get('timeEnd').value.split(':')[1];
            const intervalEn = moment(c.get('intervalEnd').value);
            intervalEn.set({ h: hourEnd, m: minuteEnd, s: 0 }).toDate();
            if (intervalSt.isBefore(intervalEn)) {
                return null;
            } else return { notValidTimeRange: true };
        }
    }

    public static userRolesValidator(f: FormArray) {
        if (f) {
            const array: string[] = f.value;
            return array.filter((el) => el != '').length != 0 ? null : { UserRolesValidator: true };
        }
        return null;
    }

    public static periodValidator(f: FormGroup) {
        if (f.value.workShiftFrequencyType && f.value.hoursAtPeriod > 0) {
            if (f.value.workShiftFrequencyType.id == 1 && f.value.hoursAtPeriod < 24) {
                return null;
            } else if (f.value.workShiftFrequencyType.id == 1 && f.value.hoursAtPeriod >= 24) {
                return { DayRangeValidator: true };
            }
            if (f.value.workShiftFrequencyType.id == 2 && f.value.hoursAtPeriod < 168) {
                return null;
            } else if (f.value.workShiftFrequencyType.id == 2 && f.value.hoursAtPeriod >= 168) {
                return { WeekRangeValidator: true };
            }
            if (f.value.workShiftFrequencyType.id == 4 && f.value.hoursAtPeriod < 720) {
                return null;
            } else if (f.value.workShiftFrequencyType.id == 4 && f.value.hoursAtPeriod >= 720) {
                return { MonthRangeValidator: true };
            }
        } else if ((f.value.workShiftFrequencyType == undefined || f.value.workShiftFrequencyType == '') && (f.value.hoursAtPeriod == 0 || f.value.hoursAtPeriod == undefined)) {
            return null;
        } else {
            return { MissingFieldValidator: true };
        }
        return null;
    }

    public static serviceWithoutContractValidator(f: FormGroup) {
        if (f.value.contractId == 0 && f.value.customerId == 0) {
            return { missingReference: true };
        } else return null;
    }

    public static atLeastOneDayValidator(f: FormArray) {
        if (f) {
            const array: any[] = f.value;
            return array.filter((el) => el.isDaySelected != '').length != 0 ? null : { DayValidator: true };
        }
        return null;
    }

    public static daysHourFieldValidator(f: FormArray) {
        for (var v = 0; v < f.value.hourRange.length; v++) {
            if (f.get('isDaySelected').value && (f.value.hourRange[v].hourStart == '' || f.value.hourRange[v].hourEnd == '')) {
                return { hourNotValid: 'true' };
            } else {
                const h1 = f.value.hourRange[v].hourStart.split(':')[0];
                const m1 = f.value.hourRange[v].hourStart.split(':')[1];

                const h2 = f.value.hourRange[v].hourEnd.split(':')[0];
                const m2 = f.value.hourRange[v].hourEnd.split(':')[1];

                if (h1 > h2 || (h1 == h2 && m1 > m2)) return { hourNotValid: true };
            }
        }
    }

    public static twoTimesDateValidator(f) {
        var startTime = f.value.timeStart;
        var endTime = f.value.timeEnd;
        if (!startTime) {
            const startDay = moment(f.value.intervalStart);
            startTime = startDay.set({ h: new Date(startDay.toDate()).getHours() }).format('HH:mm');
        }
        if (!endTime) {
            const endDay = moment(f.value.intervalStart);
            endTime = endDay.set({ h: new Date(endDay.toDate()).getHours() }).format('HH:mm');
        }
        if (startTime && endTime) {
            const fakeDateStart = moment(new Date(f.value.intervalStart));
            const fakeDateEnd = moment(new Date(f.value.intervalEnd));
            const hourStart = startTime.split(':')[0];
            const minuteStart = startTime.split(':')[1];
            fakeDateStart.set({ h: hourStart, m: minuteStart, s: 0 });
            const hourEnd = endTime.split(':')[0];
            const minuteEnd = endTime.split(':')[1];
            fakeDateEnd.set({ h: hourEnd, m: minuteEnd, s: 0 });

            if (fakeDateStart.isAfter(fakeDateEnd)) {
                return { notValidTimeRange: true };
            } else {
                return null;
            }
        }
        return null;
    }
    /**
     * Here it's checked if, for each period (date + hour range) selected
     * there are an other period that overlaps in the same hour.
     * @param f
     */
    public static timeRangesValidator(f) {
        for (var v = 0; v < f.value.length - 1; v++) {
            for (var w = 0; w < f.value.length; w++) {
                if (f.value[w] !== f.value[v] && f.value[w].hourStart && f.value[w].hourEnd && f.value[v].hourStart && f.value[v].hourEnd) {
                    //date 1
                    const fakeDate1Start = moment(new Date());
                    const fakeDate1End = moment(new Date());
                    const hourStartDate1 = f.value[v].hourStart.split(':')[0];
                    const minuteStartDate1 = f.value[v].hourStart.split(':')[1];
                    fakeDate1Start.set({ h: hourStartDate1, m: minuteStartDate1, s: 0 });
                    const hourEndDate1 = f.value[v].hourEnd.split(':')[0];
                    const minuteEndDate1 = f.value[v].hourEnd.split(':')[1];
                    fakeDate1End.set({ h: hourEndDate1, m: minuteEndDate1, s: 0 });

                    //date 2
                    const fakeDate2Start = moment(new Date());
                    const fakeDate2End = moment(new Date());
                    const hourStartDate2 = f.value[w].hourStart.split(':')[0];
                    const minuteStartDate2 = f.value[w].hourStart.split(':')[1];
                    fakeDate2Start.set({ h: hourStartDate2, m: minuteStartDate2, s: 0 });
                    const hourEndDate2 = f.value[w].hourEnd.split(':')[0];
                    const minuteEndDate2 = f.value[w].hourEnd.split(':')[1];
                    fakeDate2End.set({ h: hourEndDate2, m: minuteEndDate2, s: 0 });

                    if (fakeDate1Start.isBefore(fakeDate2Start) && (fakeDate1End.isAfter(fakeDate2Start) || fakeDate1End.isAfter(fakeDate2End))) {
                        return { notValidTimeRange: true };
                    } else if (fakeDate2Start.isBefore(fakeDate1Start) && fakeDate2End.isAfter(fakeDate1Start)) {
                        return { notValidTimeRange: true };
                    }
                }
            }
        }
        return null;
    }

    /**
     * Check current language, then validate decimal number with comma or point
     * @param c
     * @returns
     */
    public static twoDecimalTimeRegexValidator(c: FormControl) {
        if (c.value) {
            let isValid = true;
            switch (abp.localization.currentLanguage.name) {
                case LocalizationConsts.Italian: {
                    isValid = /^[0-9]+([,][0-5][0-9])?$/.test(c.value);
                    break;
                }
                case LocalizationConsts.English: {
                    isValid = /^[0-9]+([.][0-5][0-9])?$/.test(c.value);
                    break;
                }
            }
            return isValid
                ? null
                : {
                      TimeValidator: true,
                  };
        } else return null;
    }

    public static fundAmountValidator(c: FormControl) {
        let nativeValue;
        if (c && c.value) {
            const formatter = new Intl.NumberFormat(abp.localization.currentLanguage.name + '-' + abp.localization.currentLanguage.name.toUpperCase(), {
                style: 'decimal',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
            let stringifiedValue = c.value.toString();

            let sampleFormattedNumber = formatter.formatToParts(123456.7);
            const escapedDecimalDivider = `\\${sampleFormattedNumber.find((el) => el.type == 'decimal').value}`;
            const escapedGroupDivider = `\\${sampleFormattedNumber.find((el) => el.type == 'group').value}`;
            var groupDividerRegex = new RegExp(`${escapedGroupDivider}`, 'gi');
            var decimalDividerRegex = new RegExp(`${escapedDecimalDivider}`, 'gi');
            nativeValue = parseFloat(stringifiedValue.replace(groupDividerRegex, '').replace(decimalDividerRegex, '.'));

            if (!isNaN(nativeValue)) {
                return null;
            } else {
                return { notValidFundAmount: true };
            }
        }
        return null;
    }

    public static minDate(date?: moment.Moment): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (date && date.isValid() && control.value) {
                const dateToCheck: moment.Moment = moment(control.value);
                if (dateToCheck.isValid() && dateToCheck.isBefore(date)) {
                    return {
                        minDate: 'minDate',
                    } as ValidationErrors;
                }
            }
            return null;
        };
    }

    public static maxDate(date?: moment.Moment): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (date && date.isValid() && control.value) {
                const dateToCheck: moment.Moment = moment(control.value);
                if (dateToCheck.isValid() && dateToCheck.isAfter(date)) {
                    return {
                        maxDate: 'maxDate',
                    } as ValidationErrors;
                }
            }
            return null;
        };
    }
}
